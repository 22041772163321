import { cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Injectable({ providedIn: 'root' })
export class StateService {
  private currentSub: Subscription;
  private current: ConfigurationState = null;
  private history: ConfigurationState[] = [];

  constructor(private store: Store) {}

  initState() {
    this.store.dispatch(InstallationActions.resetState());
  }

  saveCurrentStateToLocalStorage() {
    const serializedCurrent = JSON.stringify(this.current);
    localStorage.setItem('current-state', serializedCurrent);
  }

  subscribeToCurrentAndSave() {
    if (this.currentSub) this.currentSub.unsubscribe();

    const select = InstallationSelectors.selectCurrentSummary();
    this.currentSub = this.store.select(select).subscribe((current) => {
      this.current = cloneDeep(current);
      this.saveCurrentToLocalStorage();
    });
  }

  saveCurrentToLocalStorage() {
    const serializedCurrent = JSON.stringify(this.current);
    localStorage.setItem('current-state', serializedCurrent);
  }

  loadCurrentFromLocalStorage(): ConfigurationState {
    const serializedCurrent = localStorage.getItem('current-state');
    const current = JSON.parse(serializedCurrent);

    if (current !== null) return current;
    return null;
  }

  addCurrentToHistory(current: ConfigurationState) {
    this.history = this.loadConfigurationHistory();
    this.history.push(current);
    const serializedHistory = JSON.stringify(this.history);
    localStorage.setItem('c-belt-history', serializedHistory);
  }

  loadConfigurationHistory(): ConfigurationState[] {
    const serializedHistory = localStorage.getItem('c-belt-history');
    const history = JSON.parse(serializedHistory);

    if (history && history.length) {
      this.history = history;
    } else {
      this.history = [];
    }
    return this.history;
  }

  updateConfigurationHistory(current: ConfigurationState) {
    let configToReplace = this.history.find((x) =>
      x.conveyors.find((y) => y.machineId === current.conveyors[0].machineId)
    );

    let index = this.history.indexOf(configToReplace);
    if (index !== -1) {
      this.history[index] = current;
    }

    const serializedHistory = JSON.stringify(this.history);
    localStorage.setItem('c-belt-history', serializedHistory);
  }

  removeConfigFromHistory(config: ConfigurationState) {
    if (config.conveyors.length === 0) return;

    this.history = this.history.filter(
      (historyConfig) =>
        !historyConfig.conveyors.some((conveyor) =>
          config.conveyors.some((inputConveyor) => inputConveyor.machineId === conveyor.machineId)
        )
    );

    const serializedHistory = JSON.stringify(this.history);
    localStorage.setItem('c-belt-history', serializedHistory);
  }

  getGatewaysByCustomerNumber(customerNumber: string) {
    let history = this.loadConfigurationHistory();
    let gateways = [];
    for (let config of history) {
      if (config.company.customerNumber === customerNumber) {
        gateways.push(config.gateway);
      }
    }
    return gateways;
  }
}
