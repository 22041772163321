<div class="mobile">
  <app-mobile-header
    [header]="'Pairing a Belt and Conveyor'"
    [routerLinkUrl]="[
      '/installing',
      companyId,
      'conveyor-reader-configuration'
    ]">
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <div
      class="bg-white card p-3"
      *ngIf="!rfidTagId">
      <div class="text-center">
        Connect the RFID reader to your phone and scan the belt chip
      </div>

      <div class="small-grey text-center mt-4 px-5">
        Waiting for the reader to pick up the RFID signal
      </div>

      <div class="d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-primary mt-2 me-2"
          (click)="startScanning()">
          Start Scanning
        </button>
        <button
          type="button"
          class="btn btn-primary mt-2 ms-2"
          (click)="stopScanning()">
          Stop Scanning
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-center my-3">OR</div>

    <div class="bg-white card p-3">
      <label
        for="beltRfid"
        class="form-label"
        >RFID</label
      >
      <input
        [(ngModel)]="belt.rfidTagId"
        class="form-control mt-2"
        name="beltRfid"
        type="text"
        placeholder="eg: 34735693" />
      <button
        (click)="navToHome()"
        type="button"
        class="btn btn-primary mt-4">
        Pair
      </button>
    </div>

    <div
      class="bg-white card p-3 mt-2"
      *ngIf="rfidTagId">
      <div class="text-center">RFID Tag ID:</div>
      <div class="text-center">{{ rfidTagId }}</div>
    </div>

    <button
      *ngIf="rfidTagId"
      (click)="navToHome()"
      type="button"
      class="btn btn-primary mt-4">
      Pair Belt to Conveyor
    </button>
  </div>
</div>
