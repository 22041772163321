import { Injectable } from '@angular/core';

import { Customer } from 'src/app/models/customer.model';

import { CBeltApiService } from '../api/cBeltApi.service';

@Injectable()
export class OrganizationsService {
  constructor(private cBeltApiService: CBeltApiService) {}

  async getOrganizations(): Promise<Customer[]> {
    const options = { params: { organizationType: 'Customer Organization' } };
    const response = await this.cBeltApiService.get('/organizations', options);
    return response['result']['items'].map((x) => Customer.fromJson(x));
  }

  async getCustomerById(id: string): Promise<Customer> {
    const customers = await this.getOrganizations();
    return customers.find((x) => x.id === id);
  }
}
