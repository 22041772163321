<div class="d-flex justify-content-between align-items-center mb-4">
  <h4 class="text-secondary">Inspections</h4>
  <button class="btn btn-secondary" (click)="alertComingSoon()">
    New Inspection
  </button>
</div>
<table class="table table-hover border user-select-none bg-white">
  <thead>
    <tr>
      <th>Customer</th>
      <th>Activity</th>
      <th>Status</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let survey of inspections"
      (click)="editSurvey(survey)"
      class="pointer"
    >
      <td>{{ survey.name }}</td>
      <td>{{ survey.created | date }}</td>
      <td>{{ survey.wtl || "-" }}</td>
    </tr>
    <tr *ngIf="!inspections.length">
      <td colspan="3">No Inspections yet.</td>
    </tr>
  </tbody>
</table>
