import { TranslateService } from './../../../../services/translate.service';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

import { BeltFilter } from '../belt-list.component';
import { Belt } from './../../../../models/belt.model';
import { Survey } from './../../../../models/survey.model';

import { UtilService } from './../../../../utils/util.service';

@Component({
  selector: 'app-mobile-belt-list',
  templateUrl: './mobile-belt-list.component.html',
  styleUrl: './mobile-belt-list.component.scss'
})
export class MobileBeltListComponent {
  @Input() survey: Survey;
  @Input() appliedFilters: BeltFilter;
  @Input() filterCount: number;
  @Input() hasActiveFilter: boolean;
  @Input() belts: Belt[] = [];

  @Input() searchText: string;
  @Output() searchTextChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() copyClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadSurveyCsvClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private utils: UtilService,
    public t:TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.belts) {
      this.belts = this.utils.sortAlphaNumeric(this.belts, 'created', false);
    }
  }

  onItemClick(belt: Belt) {
    this.itemClick.emit(belt)
  }
  onCopyClick(belt: Belt) {
    this.copyClick.emit(belt)
  }
  onRemoveClick(belt: Belt) {
    this.removeClick.emit(belt)
  }
  onFilterButtonClick() {
    this.filterButtonClick.emit()
  }
  onDownloadSurveyCsvClick() {
    this.downloadSurveyCsvClick.emit()
  }
  onSearchTextChange() {
    this.searchTextChange.emit(this.searchText);
  }
}
