<div class="mobile">
  <app-mobile-header [header]="'Select the configuration to edit'" [routerLinkUrl]="['/c-belt']">
  </app-mobile-header>

  <div class="mx-3">
    <table class="table table-hover user-select-none mt-3" *ngIf="history.length">
      <thead>
        <tr>
          <th>Customer</th>
          <th>Machines</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let config of history">
          <td (click)="navTo(config.company.customerNumber, config.conveyors[0].machineId)">
            {{ config.company.customer_name }}
          </td>
          <td (click)="navTo(config.company.customerNumber, config.conveyors[0].machineId)">
            <ul class="machine-list" *ngFor="let machine of config.machines">
              <li>{{ "• " + machine.machineName }}</li>
            </ul>
          </td>
          <td>
            <i class="fa fa-trash ps-3" (click)="removeConfigFromHistory(config)"></i>
          </td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="!history.length" class="bg-white card p-3 mt-3">No configurations in history.</div>
  </div>
</div>
