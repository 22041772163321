import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { environment } from 'src/environments/environment';

import { AuthService } from 'src/app/services/auth/auth.service';
import { Survey } from 'src/app/models/survey.model';
import { Belt } from 'src/app/models/belt.model';
import { Customer } from 'src/app/models/customer.model';

import * as BeltActions from 'src/app/state/belt/belt.actions';
import * as CustomerActions from 'src/app/state/customer/customer.actions';
import * as SurveyActions from 'src/app/state/survey/survey.actions';

@Injectable()
export class FetchService {
  constructor(
    private store: Store,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  public async fetchServerDataAndUpdateState(): Promise<void> {
    const user = this.authService.getUser();

    const surveys = await this.fetchSurveys();
    const belts = await this.fetchBelts();
    const customers = await this.fetchCustomers();

    const userConfirm = this.authService.getUser();
    if (!userConfirm || user.id != userConfirm.id) return;

    this.store.dispatch(BeltActions.fetchBeltsSuccess({ belts }));
    this.store.dispatch(CustomerActions.fetchCustomersSuccess({ customers }));
    this.store.dispatch(SurveyActions.fetchSurveysSuccess({ surveys }));
  }

  /*
    TODO: add pagination to the fetching
  */

  private async fetchSurveys(): Promise<Survey[]> {
    const obs = this.httpClient.get<Survey[]>(`${environment.baseUrl}/surveys`);
    return this.observableToPromise(obs);
  }

  private async fetchCustomers(): Promise<Customer[]> {
    const obs = this.httpClient.get<Customer[]>(`${environment.baseUrl}/customers`);
    return this.observableToPromise(obs);
  }

  private async fetchBelts(): Promise<Belt[]> {
    const obs = this.httpClient.get<Belt[]>(`${environment.baseUrl}/belts`);
    return this.observableToPromise(obs);
  }

  private observableToPromise<T>(observable: Observable<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      observable.subscribe({ next: resolve, error: reject });
    });
  }
}
