import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { CBelt } from 'src/app/models/cBelt.model';
import { Conveyor } from 'src/app/models/conveyor.model';

import { BeltsService } from 'src/app/services/c-belt/belts.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';

@Component({
  selector: 'app-belt-validation',
  templateUrl: './belt-validation.component.html',
  styleUrl: './belt-validation.component.scss',
})
export class BeltValidationComponent implements OnInit, OnChanges {
  belt: CBelt;
  belts: CBelt[] = [];
  @Input() conveyor: Conveyor;

  constructor(
    private beltService: BeltsService,
    private alertService: AlertService,
    private loadingService: LoadingService
  ) {}

  async ngOnInit(): Promise<void> {
    this.belts = await this.beltService.getAllBelts();
    await this.assignBelt(this.conveyor.id);
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.conveyor && changes.conveyor.currentValue) {
      await this.assignBelt(this.conveyor.id);
    }
  }

  async assignBelt(conveyorId: string) {
    this.loadingService.show();
    try {
      this.belt = new CBelt();
      this.belt = this.belts.find((x) => x.conveyorId == conveyorId);
      let response = await this.beltService.getSalesLocationCode(this.belt.orderNumber);
      if (response.result) {
        this.belt.cpr = response.result.cpr;
        this.belt.salesLocationCode = response.result.salesLocationCode;
      }
    } catch (err) {
      this.alertService.handleCBeltError(err);
    }
    this.loadingService.hide();
  }

  public complete(): boolean {
    return (
      this.belt.orderNumber !== null &&
      this.belt.conveyorId !== null &&
      this.belt.rfidTagId !== null &&
      this.belt.width !== null &&
      this.belt.length !== null
    );
  }

  public incomplete(): boolean {
    return (
      this.belt.orderNumber === null ||
      this.belt.conveyorId === null ||
      this.belt.rfidTagId === null ||
      this.belt.width === null ||
      this.belt.length === null
    );
  }

  public emptyOptionalField(value: string): boolean {
    return value === null || value === '' || value === undefined;
  }
}
