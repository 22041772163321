import { CBeltMachine } from 'src/app/models/cBeltMachine.model';
import { cloneDeep } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Customer } from 'src/app/models/customer.model';
import { Conveyor } from 'src/app/models/conveyor.model';

import { StateService } from 'src/app/services/c-belt/state.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-system-setup',
  templateUrl: './system-setup.component.html',
  styleUrl: './system-setup.component.scss',
})
export class SystemSetupComponent implements OnInit {
  editing: boolean;
  companyId: string;
  customer: Customer;
  current: ConfigurationState = null;
  containsRfidReader: boolean = true;

  conveyors: Conveyor[] = [];

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private stateService: StateService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.store.dispatch(InstallationActions.clearCurrentMachine());
    this.store.dispatch(InstallationActions.clearCurrentConveyor());

    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToCurrentConfiguration();
    });
  }

  subscribeToCurrentConfiguration() {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.customer = this.current.company;
        this.containsRfidReader = this.current.containsRfidReader;
        this.editing = this.current.editing;
      });
  }

  fetchConveyorsForMachine(id: string): Conveyor[] {
    return this.current.conveyors.filter((conveyor) => conveyor.machineId === id);
  }

  addNewMachine() {
    this.store.dispatch(InstallationActions.clearCurrentMachine());
    this.navToAddConveyor();
  }

  addConveyorToMachine(machine) {
    this.store.dispatch(
      InstallationActions.setCurrentMachine({
        machine: machine,
      })
    );
    this.navToAddConveyor();
  }

  editConveyorAndMachine(machine: CBeltMachine, conveyor: Conveyor) {
    this.store.dispatch(
      InstallationActions.setCurrentMachine({
        machine: machine,
      })
    );
    this.store.dispatch(
      InstallationActions.setCurrentConveyor({
        conveyor: conveyor,
      })
    );
    this.navToAddConveyor();
  }

  navToAddConveyor() {
    this.router.navigate(['/installing', this.companyId, 'conveyor-reader-configuration']);
  }
}
