<div class="mobile">
  <app-mobile-header
    [header]="'Pairing the Conveyor with a reader box'"
    [routerLinkUrl]="[
      '/installing',
      companyId,
      'conveyor-reader-configuration'
    ]"
  >
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <form name="configuration-form" class="d-flex">
      <div class="bg-white card p-3" *ngIf="!tagId">
        <div>
          Hold the top of your phone against the reader to scan its NFC tag
        </div>
        <button
          type="button"
          class="btn btn-primary mt-4"
          (click)="navToPairReaderManually()"
        >
          Or: Add Manually
        </button>
      </div>
    </form>

    <form name="configuration-form" class="d-flex mt-3" *ngIf="!tagId">
      <div class="bg-white card p-3">
        <div>
          Please ensure NFC is enabled on your device, once activated navigate
          back to this screen to scan.
        </div>
      </div>
    </form>

    <form name="nfc-result-form" class="d-flex mt-3" *ngIf="tagId">
      <div class="bg-white card p-3">
        <div>ID: {{ tagId }}</div>
        <button type="button" class="btn btn-primary mt-3" (click)="continue()">
          Continue
        </button>
      </div>
    </form>
  </div>
</div>
