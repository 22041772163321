import { BaseState } from '../base.state';

import { CBelt } from 'src/app/models/cBelt.model';
import { Sensor } from 'src/app/models/sensor.model';
import { Gateway } from 'src/app/models/gateway.model';
import { Customer } from 'src/app/models/customer.model';
import { Conveyor } from 'src/app/models/conveyor.model';
import { CBeltMachine } from 'src/app/models/cBeltMachine.model';
import { ConveyorReader } from 'src/app/models/conveyorReader.model';

export class InstallationState extends BaseState {
  current: ConfigurationState;
}

export class ConfigurationState extends BaseState {
  company: Customer;
  belts: CBelt[];
  belt: CBelt;
  conveyors: Conveyor[];
  conveyor: Conveyor;
  gateways: Gateway[];
  gateway: Gateway;
  conveyorReaders: ConveyorReader[];
  conveyorReader: ConveyorReader;
  machines: CBeltMachine[];
  machine: CBeltMachine;
  readerCalibrated: boolean;
  sensors: Sensor[];
  sensorsForConveyor: Sensor[];
  sensor: Sensor;
  newSensorId: any;
  editing: boolean;
  tempEdit: boolean;
  containsRfidReader: boolean;
  isRndUser: boolean;
  pairingRfid: boolean;
}

export const initialState = {
  current: {
    company: null,
    belts: [],
    belt: null,
    conveyors: [],
    conveyor: null,
    gateways: [],
    gateway: null,
    conveyorReaders: [],
    conveyorReader: null,
    machines: [],
    machine: null,
    readerCalibrated: false,
    sensors: [],
    sensorsForConveyor: [],
    sensor: null,
    newSensorId: null,
    editing: false,
    tempEdit: false,
    containsRfidReader: false,
    isRndUser: false,
    pairingRfid: false,
  },
};
