<app-desktop-header
  [header]="t.translate('_SURVEYS')"
  [showBackButton]="false">
  <div content>
    <button
      class="btn btn-primary"
      (click)="onNewSurveyClick(surveyModal)"
      data-testid="new-survey-button">
      {{ t.translate("_NEW_SURVEY") }}
    </button>
  </div>
</app-desktop-header>

<div class="mx-lg-5 mb-3">
  <div class="desktop mt-lg-6">
    <div class="d-flex align-items-center my-3">
      <div class="col">
        <app-search-input
          [(searchText)]="searchText"
          (searchTextChange)="filterAndMapSurveys()"
          data-testid="desktop-survey-search">
        </app-search-input>
      </div>

      <app-filter-button
        [hasActiveFilter]="hasActiveFilter()"
        [filterCount]="getFilterCount()"
        (filterButtonClick)="showFilterModal()">
      </app-filter-button>
    </div>

    <div class="border bg-white rounded mb-5 overflow-x">
      <app-table
        data-testid="survey-table"
        [table]="surveyTable"
        (rowClick)="navigateToEditSurvey($event)"
        sortColumn="date"
        sortDirection="DESC">
      </app-table>
    </div>
  </div>

  <div class="mobile">
    <div class="px-2">
      <h2 class="text-center header mt-3">{{ t.translate("_SURVEYS") }}</h2>
      <div class="d-flex align-items-center mt-3">
        <app-search-input
          [(searchText)]="searchText"
          (searchTextChange)="filterAndMapSurveys()"
          class="col"
          data-testid="mobile-search-surveys">
        </app-search-input>

        <app-filter-button
          [hasActiveFilter]="hasActiveFilter()"
          [filterCount]="getFilterCount()"
          (filterButtonClick)="this.showFilterModal()">
        </app-filter-button>
      </div>
    </div>

    <div class="mt-2 px-2 mb-5">
      <app-mobile-survey-list
        [surveys]="filteredSurveys"
        (removeSurveyClick)="onRemoveSurvey($event)"
        (addSurveyClick)="onNewSurveyClick(surveyModal)">
      </app-mobile-survey-list>
    </div>
  </div>
</div>

<ng-template
  #surveyModal
  let-modal>
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-basic-title">
      {{ t.translate("_CREATE_A_NEW_SURVEY") }}
    </h4>
    <div
      class="close pointer fs-3"
      aria-label="Close"
      (click)="hideModal()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <div
      class="p-2 mb-3"
      *ngIf="!allCustomerKeyValues">
      {{ t.translate("_LOADING") }} {{ t.translate("_CUSTOMERS") }}...
      {{ t.translate("_PLEASE_WAIT") }}
    </div>
    <div
      class="p-2 mb-3"
      *ngIf="allCustomerKeyValues">
      <div>
        <div
          class="d-flex flex-column align-items-center justify-content-between gap-2">
          <ng-container *ngIf="allCustomerKeyValues.length > 0">
            <div class="position-relative w-100">
              <label
                class="form-label"
                for="site">
                {{ t.translate("_CUSTOMER_SITE_LOCATION") }}
              </label>
              <ng-select
                [placeholder]="t.translate('_CHOOSE_EXISTING_CUSTOMER')"
                [(items)]="allCustomerKeyValues"
                [(ngModel)]="currentCustomerId"
                bindLabel="key"
                bindValue="value"
                data-testid="choose-survey-dropdown">
              </ng-select>
            </div>

            <div>{{ t.translate("_OR") }}</div>
          </ng-container>

          <div *ngIf="!allCustomerKeyValues.length">
            {{ t.translate("_NO_CUSTOMERS_FOUND") }}
          </div>
          <button
            class="btn btn-outline-primary"
            (click)="onAddCustomerClick()">
            {{ t.translate("_ADD_NEW_CUSTOMER") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      data-testid="survey-proceed-button"
      (click)="createSurvey()">
      {{ t.translate("_PROCEED") }}
    </button>
  </div>
</ng-template>

<ng-template
  #filterModal
  let-modal>
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-basic-title">
      {{ t.translate("_FILTER") }}
    </h4>
    <div
      class="close pointer fs-3"
      aria-label="Close"
      (click)="hideModal()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <div class="p-2 mb-3">
      <label
        class="mb-3"
        for="site">
        {{ t.translate("_SURVEY_CUSTOMERS") }}
      </label>
      <ng-select
        [placeholder]="t.translate('_SELECT_SURVEY_CUSTOMERS')"
        [(items)]="sites"
        [(ngModel)]="selectedFilters.sites"
        bindLabel="name"
        bindValue="value"
        [multiple]="true"
        [notFoundText]="t.translate('_NOTHING_FOUND')">
      </ng-select>
    </div>

    <div class="p-2 mb-3">
      <label
        class="mb-3"
        for="site">
        {{ t.translate("_SURVEY_OWNERS") }}
      </label>
      <ng-select
        [placeholder]="t.translate('_SELECT_SURVEY_OWNERS')"
        [(ngModel)]="selectedFilters.owners"
        [multiple]="true"
        [notFoundText]="t.translate('_NOTHING_FOUND')">
        <ng-option
          *ngFor="let owner of owners"
          [value]="owner">
          {{ owner | lowercase }}
        </ng-option>
      </ng-select>
    </div>

    <div class="p-2 mb-3">
      <label class="mb-3">{{ t.translate("_DATE_RANGE") }}</label>
      <div class="position-relative d-flex align-items-center">
        <input
          type="text"
          [placeholder]="t.translate('_SELECT_THE_DATE_RANGE_OF_SURVEYS')"
          class="form-control"
          [maxDate]="maxDate"
          [bsConfig]="bsConfig"
          [(ngModel)]="selectedFilters.dates"
          bsDaterangepicker />
        <div
          class="clear-date"
          title="Clear"
          (click)="clearSelectedFilterDates()"
          *ngIf="selectedFilters.dates.length">
          &times;
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-outline-danger me-2"
        (click)="clearFilters()">
        {{ t.translate("_CLEAR_FILTER") }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="applyFilters()">
        {{ t.translate("_FILTER") }}
      </button>
    </div>
  </div>
</ng-template>

<app-install-button></app-install-button>
