import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { UtilService } from './../../../utils/util.service';
import { AuthService, User } from '../../../services/auth/auth.service';
import { MsalCBeltService } from '../../../services/c-belt/msal.service';
import { ForageService } from './../../../services/forage/forage.service';
import { LanguageFlags, TranslateService } from './../../../services/translate.service';

import { Language } from './../../../models/language';

import { StorageService } from 'src/app/services/storage/storage.service';
import { AuthRole } from 'src/app/models/userAzureDetails.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @Input() isProduction: boolean;
  @Output() closeSidebar: EventEmitter<any> = new EventEmitter<any>();

  user: User;
  version: string = environment.version;

  currentLanguage: Language;
  languageSub: Subscription;

  constructor(
    private router: Router,
    private msalService: MsalService,
    private authService: AuthService,
    private forageService: ForageService,
    private storageService: StorageService,
    private msalCBeltService: MsalCBeltService,
    public t: TranslateService,
    public utils: UtilService
  ) {}

  async ngOnInit() {
    this.languageSub = this.t.subscribeToLanguageChanges((languages) => {
      languages = languages.map((language) => ({
        ...language,
        country_flag: LanguageFlags[language.key],
      }));
      const languageKey = this.t.getLanguageKey();
      this.currentLanguage = languages.find((lang) => lang.key == languageKey);
    });
  }

  public getUserEmail() {
    this.user = this.authService.getUser();
    return this.user?.email;
  }

  public isAdmin() {
    this.user = this.authService.getUser();
    if (!this.user) return false;
    return this.user.role == 'admin' || this.isSysAdmin();
  }

  public isSysAdmin(): boolean {
    if (!this.user) return false;
    return Object.values(this.user.organisationRoles).some((roles) =>
      roles.includes(AuthRole.SysAdmin)
    );
  }

  public onClose() {
    this.closeSidebar.emit();
  }

  public navToRoute(route): void {
    this.onClose();
    this.router.navigate([route]);
  }

  public async logout() {
    await this.forageService.clearForage();
    this.storageService.setUserData(null);

    if (this.isMobileOperatingSystem()) {
      this.onClose();
      await this.msalCBeltService.signOut();
      await this.msalCBeltService.interactiveSignIn();
    } else {
      const accounts = this.msalService.instance.getAllAccounts();
      const currentAccount = accounts[0];
      await this.msalService.instance.logoutRedirect({
        account: currentAccount,
        postLogoutRedirectUri: environment.logoutRedirectUri,
      });
    }
  }

  public isMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;
    return /android/i.test(userAgent);
  }
}
