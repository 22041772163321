<div class="mobile" *ngIf="survey">
    <app-mobile-header 
        [header]="survey?.customer?.customer_name" 
        [routerLinkUrl]="['/surveys']">
    </app-mobile-header>
    
    <div class="px-2 mt-2">
        <div class="d-flex align-items-center">
            <app-search-input 
                class="col" 
                [(searchText)]="searchText" 
                (searchTextChange)="onSearchTextChange()">
            </app-search-input>
            
            <app-filter-button 
                [hasActiveFilter]="hasActiveFilter"
                [filterCount]="filterCount"
                (filterButtonClick)="onFilterButtonClick()">
            </app-filter-button>
        </div>
    </div>

    <div class="bg-white rounded border m-2 mb-5 overflow-x">
        <div class="d-flex align-items-center justify-content-between p-2 border-bottom">
            <h1 class="header my-2">{{t.translate("_BELT_CONFIGS")}}</h1>
            <div class="d-flex gap-2">
                <button class="btn btn-outline-secondary overflow-hidden" (click)="onDownloadSurveyCsvClick()">
                    <i class="fa fa-download"></i>
                </button>
                <button class="small btn btn-primary d-flex align-items-center" 
                    [routerLink]="['/surveys', survey.id, 'belts', 'create']" 
                    data-testid="mobile-add-belt-config-button">
                    <i class="material-symbols-outlined pe-2">add</i>
                    {{t.translate("_ADD")}}
                </button>
            </div>
        </div>

        <app-mobile-belt-item-card 
            [belt]="belt"
            (itemClick)="onItemClick($event)"
            (copyClick)="onCopyClick($event)"
            (removeClick)="onRemoveClick($event)"
            *ngFor="let belt of belts">
        </app-mobile-belt-item-card>

        <div class="p-3" *ngIf="!belts?.length">{{t.translate("_NOTHING_FOUND")}}</div>
    </div>
</div>