import { cloneDeep } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Customer } from 'src/app/models/customer.model';
import { Conveyor } from 'src/app/models/conveyor.model';
import { CBeltMachine } from 'src/app/models/cBeltMachine.model';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-system-setup-pair-rfid',
  templateUrl: './system-setup-pair-rfid.component.html',
  styleUrl: './system-setup-pair-rfid.component.scss',
})
export class SystemSetupPairRfidComponent implements OnInit {
  editing: boolean;
  companyId: string;
  customer: Customer;
  current: ConfigurationState = null;
  containsRfidReader: boolean = true;

  conveyors: Conveyor[] = [];

  constructor(private store: Store, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.store.dispatch(InstallationActions.clearCurrentMachine());
    this.store.dispatch(InstallationActions.clearCurrentConveyor());

    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToCurrentConfiguration();
    });
  }

  subscribeToCurrentConfiguration() {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.customer = this.current.company;
        this.containsRfidReader = this.current.containsRfidReader;
        this.editing = this.current.editing;
      });
  }

  fetchConveyorsForMachine(id: string): Conveyor[] {
    return this.current.conveyors.filter((conveyor) => conveyor.machineId === id);
  }

  selectConveyorToPairRfid(machine: CBeltMachine, conveyor: Conveyor) {
    this.store.dispatch(InstallationActions.setCurrentMachine({ machine: machine }));
    this.store.dispatch(InstallationActions.setCurrentConveyor({ conveyor: conveyor }));
    this.store.dispatch(InstallationActions.togglePairingRfid({ pairingRfid: true }));
    this.router.navigate(['/installing', this.companyId, 'system-setup-pair-rfid', 'select-belt']);
  }
}
