<div class="mobile">
  <app-mobile-header
    [header]="'Pair RFID'"
    [routerLinkUrl]="['/installing', companyId, 'system-setup-pair-rfid']"
  >
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <div class="bg-white card p-3 mb-5">
      <div class="fw-bold pb-1">Select the Belt to update with ConveyorId</div>
      <div class="pb-2"><hr /></div>
      <div *ngFor="let belt of belts; let i = index">
        <div class="d-flex align-items-center justify-content-between pb-1">
          <div class="small grey ps-2">
            {{ belt.orderNumber ? belt.orderNumber : "No Order Number" }}
          </div>
          <button type="button" class="btn btn-outline-dark btn-sm" (click)="selectBelt(belt)">
            Select Belt
          </button>
        </div>
        <div class="pb-2 pt-1" *ngIf="i + 1 != belts.length"><hr /></div>
      </div>
    </div>
  </div>
</div>
