import { TranslateService } from './../../../services/translate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AlertService } from '../../../shared/services/alert/alert.service';
import { SyncService } from '../../../services/sync/sync.service';
import { AuthService } from '../../../services/auth/auth.service';
import {
  Breadcrumb,
  BreadcrumbService,
} from './../../../shared/services/breadcrumb/breadcrumb.service';
import { LoadingService } from '../../../shared/services/loading/loading.service';

import { Survey } from './../../../models/survey.model';
import { Belt } from '../../../models/belt.model';

import * as BeltActions from '../../../state/belt/belt.actions';
import * as BeltSelectors from '../../../state/belt/belt.selector';
import * as SurveySelectors from '../../../state/survey/survey.selector';

@Component({
  selector: 'app-belt-edit',
  templateUrl: './belt-edit.component.html',
  styleUrls: ['./belt-edit.component.scss'],
})
export class BeltEditComponent implements OnInit, OnDestroy {
  private surveySubscription: any;
  private beltSubscription: any;
  private routeSubscription: any;

  belt: Belt;
  survey: Survey;
  survey$: Observable<Survey>;
  belt$: Observable<Belt>;

  currProductReference: string;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService,
    private loadingService: LoadingService,
    private syncService: SyncService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    public t: TranslateService
  ) {}

  ngOnInit() {
    this.routeSubscription = this.route.params.subscribe((params) => {
      this.selectData(params);

      this.surveySubscription = this.survey$.subscribe((survey) => (this.survey = survey));

      this.beltSubscription = this.belt$.subscribe((belt) => {
        if (!belt || belt.id == this.belt?.id) return;
        this.belt = Object.assign({}, belt);
        this.currProductReference = Object.assign({}, belt).productReference;

        const crumb = new Breadcrumb(this.t.translate('_EDIT') + ' ' + this.currProductReference, [
          '/',
          'surveys',
          this.belt.surveyId,
          'belts',
          this.belt.id,
          'edit',
        ]);
        this.breadcrumbService.pushBreadcrumb(crumb);
      });
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this.surveySubscription.unsubscribe();
    this.beltSubscription.unsubscribe();
  }

  private selectData(params) {
    this.belt$ = this.store.select(BeltSelectors.selectBeltById({ id: params.belt }));
    this.survey$ = this.store.select(SurveySelectors.selectSurveyById({ id: params.survey }));
  }

  async updateBelt(belt: Belt) {
    this.loadingService.show();
    try {
      this.belt = { ...this.belt };
      belt.surveyor = { email: this.authService.getUser().email };
      belt.dirty = true;
      belt.updated = new Date();

      this.store.dispatch(BeltActions.updateBeltSuccess({ belt }));
      await this.syncService.trySync();
      this.breadcrumbService.popBreadcrumb();
      this.router.navigate(['/surveys', this.survey.id]);

      this.alertService.alertSuccess(this.t.translate('_BELT_CONFIG_UPDATED_SUCCESSFULLY'));
    } catch (err) {
      this.alertService.handleHttpError(err);
    }
    this.loadingService.hide();
  }
}
