export class CBelt {
  id: string;

  cpr: string;
  width: number;
  length: number;
  rfidTagId: string;
  conveyorId: string;
  orderNumber: string;
  salesLocationCode: string;

  static fromJson(json: any): CBelt {
    let belt = new CBelt();
    belt.id = json.id;
    belt.orderNumber = json.orderNumber;
    belt.conveyorId = json.conveyorId;
    belt.rfidTagId = json.rfidTagId;
    belt.width = json.width;
    belt.length = json.length;
    return belt;
  }
}
