export class Gateway {
  id: string;

  gatewayDeviceId: string;
  networkName: string;
  devicePassword: string;
  ipAddress: string;
  networkMask: string;
  gatewayAddress: string;
  dns: string;
  wifiType: string;
}
