import { Component, NgZone, OnInit } from '@angular/core';

import { Installation } from '../../../../../app/models/installation.model';

import { BeltsService } from '../../../../../app/services/c-belt/belts.service';
import { AlertService } from '../../../../../app/shared/services/alert/alert.service';
import { LoadingService } from './../../../../shared/services/loading/loading.service';
import { MsalCBeltService, MsalScope } from '../../../../../app/services/c-belt/msal.service';

@Component({
  selector: 'app-scanning',
  templateUrl: './scanning.component.html',
  styleUrls: ['./scanning.component.scss'],
})
export class ScanningComponent implements OnInit {
  installations: Installation[] = [];
  installation: Installation = new Installation();

  scanning: boolean = false;

  constructor(
    private ngZone: NgZone,
    private beltService: BeltsService,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private msalCBeltService: MsalCBeltService
  ) {}

  async ngOnInit(): Promise<void> {
    this.loadingService.show();
    if (this.isMobileOperatingSystem()) {
      await this.msalCBeltService.msalInit(MsalScope.cBelt);
      await this.msalCBeltService.cBeltLogin();
    }

    this.fetchInstalls();
    this.loadingService.hide();
  }

  private isMobileOperatingSystem(): boolean {
    var userAgent = navigator.userAgent || navigator.vendor;
    return /android/i.test(userAgent);
  }

  async fetchInstalls() {
    this.loadingService.show();
    try {
      this.installations = await this.beltService.getAllInstallations();
    } catch (err) {
      this.alertService.handleCBeltError(err);
    }
    this.loadingService.hide();
  }

  resetInstall() {
    this.installation = new Installation();
  }

  startScanning() {
    this.toggleScanning();
    window['plugins'].rfidScannerPlugin.scan(
      (rfid) => this.ngZone.run(() => this.onScanFound(rfid)),
      (err) => this.ngZone.run(() => this.onRfidError(err))
    );
  }

  async onScanFound(rfid: string) {
    this.stopScanning();
    this.fetchInstallDetails(rfid);
  }

  onRfidError(err) {
    this.scanning = false;
    this.alertService.alertError(JSON.stringify(err));
  }

  async fetchInstallDetails(rfid: string) {
    this.loadingService.show();
    try {
      let foundInstall = this.installations.find((x) => x.rfidTagId === rfid);
      if (foundInstall) {
        this.installation = foundInstall;
      } else {
        this.alertService.alertError('No match for this RFID Tag ID.');
      }
    } catch (err) {
      this.alertService.handleCBeltError(err);
    }
    this.loadingService.hide();
  }

  stopScanning() {
    window['plugins'].rfidScannerPlugin.stop(
      () => this.ngZone.run(() => this.onScanningStopped()),
      (err) => this.ngZone.run(() => this.onRfidError(err))
    );
  }

  private onScanningStopped() {
    this.toggleScanning();
  }

  private toggleScanning() {
    this.scanning = !this.scanning;
  }

  isValidInstall() {
    return this.installation.rfidTagId && this.installation.length && this.installation.width;
  }

  formatDateTime(date: string) {
    return new Date(date).toLocaleString();
  }
}
