import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { take } from 'rxjs';
import { Store } from '@ngrx/store';

import { Conveyor } from 'src/app/models/conveyor.model';
import { Customer } from 'src/app/models/customer.model';
import { CBeltMachine } from 'src/app/models/cBeltMachine.model';
import { ConveyorReader } from 'src/app/models/conveyorReader.model';
import { CBelt } from 'src/app/models/cBelt.model';

import { MachinesService } from 'src/app/services/c-belt/machines.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { ConveyorsService } from 'src/app/services/c-belt/conveyors.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { ModalService } from 'src/app/services/modal/modal.service';

import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-conveyor-reader-configuration',
  templateUrl: './conveyor-reader-configuration.component.html',
  styleUrls: ['./conveyor-reader-configuration.component.scss'],
})
export class ConveyorReaderConfigurationComponent implements OnInit {
  baseRoute: string;
  companyId: string;
  machineId: string;
  createdMachineId: string;

  updating: boolean = false;
  addingConveyorToMachine: boolean = false;

  conveyors: Conveyor[] = [];
  machines: CBeltMachine[] = [];

  customer: Customer = null;
  current: ConfigurationState = null;
  machineSelected: CBeltMachine = null;

  conveyor: Conveyor = new Conveyor();
  machine: CBeltMachine = new CBeltMachine();
  conveyorReader: ConveyorReader = new ConveyorReader();
  belt: CBelt;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private machinesService: MachinesService,
    private conveyorService: ConveyorsService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.route.url.subscribe((route) => {
      this.baseRoute = route[0].path;
    });
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToCurrentConfiguration();
    });
    this.fetchMachines();
  }

  subscribeToCurrentConfiguration() {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.customer = this.current.company;
        this.belt = this.current.belt;
        if (this.current.machine && this.current.conveyor) {
          this.updating = true;
          this.loadCurrent();
          this.machine = this.current.machine;
          this.machine.id = this.current.conveyor.machineId;
          this.conveyor = this.current.conveyor;
          this.conveyor.diameter = this.conveyorReader.diameter;
          this.conveyor.idleDrumDiameter = this.conveyorReader.idleDrumDiameter;
          return;
        }
        if (this.current.machine) {
          this.addingConveyorToMachine = true;
          this.loadCurrentMachine();
          this.machine = this.current.machine;
          this.machineSelected = this.current.machine;
        }
      });
  }

  loadCurrent() {
    this.conveyorReader.machineName = this.current.machine.machineName;
    this.conveyorReader.conveyorName = this.current.conveyor.aliasName;
    this.conveyorReader.description = this.current.conveyor.description;
    this.conveyorReader.machineNickName = this.current.machine.aliasName;
    this.conveyorReader.diameter = Number(this.current.conveyor.diameter) * 1000;
    if (this.current.containsRfidReader) {
      this.conveyorReader.idleDrumDiameter = null;
    } else {
      this.conveyorReader.idleDrumDiameter = Number(this.current.conveyor.idleDrumDiameter) * 1000;
    }
  }

  loadCurrentMachine() {
    this.conveyorReader.machineName = this.current.machine.machineName;
    this.conveyorReader.machineNickName = this.current.machine.aliasName;
  }

  navBackTo() {
    if (this.current.pairingRfid) {
      this.store.dispatch(InstallationActions.togglePairingRfid({ pairingRfid: false }));
      return ['/installing', this.companyId, 'system-setup-pair-rfid'];
    } else {
      return ['/installing', this.companyId, 'system-setup'];
    }
  }

  async fetchMachines() {
    this.loadingService.show();
    try {
      const machines = await this.machinesService.getMachines();
      this.machines = machines;
    } catch (err) {
      this.alertService.handleCBeltError(err);
    }
    this.loadingService.hide();
  }

  public selectMachineName(machineId) {
    this.machineId = machineId;
    this.machineSelected = this.machines.find((x) => x.id == machineId);
    this.conveyorReader.machineName = this.machineSelected.machineName;
    this.conveyorReader.machineNickName = this.machineSelected.aliasName;
  }

  async navToPairConveyor() {
    this.loadingService.show();
    try {
      if (!this.isValid()) {
        this.alertService.alertError('Please fill in required fields');
        return;
      }

      if (!this.current.containsRfidReader && !this.belt) {
        this.showBeltRequiredModal();
        return;
      }

      if (this.current.editing || this.updating) {
        await this.updateMachine();
        await this.updateConveyor();
      } else if (this.addingConveyorToMachine) {
        await this.updateMachine();
        await this.addConveyor();
      } else {
        if (!this.machineSelected) {
          await this.addMachine();
        } else {
          await this.updateMachine();
        }
        await this.addConveyor();
      }

      this.conveyorReader.id = this.machine.id + this.conveyorReader.conveyorName;
      this.store.dispatch(
        InstallationActions.updateConveyorReader({
          conveyorReader: this.conveyorReader,
        })
      );
      this.store.dispatch(
        InstallationActions.setCurrentConveyorReader({
          conveyorReader: this.conveyorReader,
        })
      );

      this.loadingService.hide();
      this.updating = false;

      if (this.current.containsRfidReader) {
        this.router.navigate([
          this.baseRoute,
          this.companyId,
          'conveyor-reader-configuration',
          'pair-conveyor-reader',
          this.conveyorReader.conveyorName,
        ]);
      } else {
        this.router.navigate([
          this.baseRoute,
          this.companyId,
          'conveyor-reader-configuration',
          'pair-belt-and-conveyor',
          this.conveyorReader.conveyorName,
        ]);
      }
    } catch (err) {
      this.loadingService.hide();
      return;
    }
  }

  private showBeltRequiredModal() {
    this.modalService.showInfoModal({
      title: 'Belt Required',
      message: 'Please fit and prepare a belt it can be paired with a conveyor',
      confirmButtonText: 'Prepare Belt',
      confirm: () => {
        this.router.navigate(['/fitting']);
      },
    });
  }

  async navToHomeWithoutRfidPairing() {
    this.loadingService.show();
    try {
      if (!this.isValid()) {
        this.alertService.alertError('Please fill in required fields');
        return;
      }

      if (this.current.editing || this.updating) {
        await this.updateMachine();
        await this.updateConveyor();
      } else {
        if (!this.machineSelected) {
          await this.addMachine();
        } else {
          await this.updateMachine();
        }
        await this.addConveyor();
      }

      this.conveyorReader.id = this.machine.id + this.conveyorReader.conveyorName;
      this.store.dispatch(
        InstallationActions.updateConveyorReader({
          conveyorReader: this.conveyorReader,
        })
      );

      this.loadingService.hide();
      this.updating = false;
      this.router.navigate(['/installing', this.companyId, 'system-setup']);
    } catch (err) {
      this.loadingService.hide();
      return;
    }
  }

  isValid() {
    if (this.current.containsRfidReader) {
      return (
        this.conveyorReader.machineName &&
        this.conveyorReader.conveyorName &&
        this.conveyorReader.diameter
      );
    } else {
      return (
        this.conveyorReader.machineName &&
        this.conveyorReader.conveyorName &&
        this.conveyorReader.idleDrumDiameter &&
        this.conveyorReader.diameter
      );
    }
  }

  async addMachine() {
    this.machine.machineName = this.conveyorReader.machineName;
    this.machine.aliasName = this.conveyorReader.machineNickName
      ? this.conveyorReader.machineNickName
      : '';
    this.machine.organizationId = this.customer.id;
    const response = await this.machinesService.addMachine(this.machine);
    this.createdMachineId = response['id'];
    this.machine.id = this.createdMachineId;
    this.alertService.alertSuccess('Machine Created Successfully!');
    this.store.dispatch(InstallationActions.updateMachine({ machine: this.machine }));
  }

  async updateMachine() {
    if (this.machineSelected && this.machineSelected.id) {
      this.machine.id = this.machineSelected.id;
    } else {
      this.machine.id = this.conveyor.machineId;
    }
    this.machine.machineName = this.conveyorReader.machineName;
    this.machine.aliasName = this.conveyorReader.machineNickName
      ? this.conveyorReader.machineNickName
      : '';
    this.machine.organizationId = this.customer.id;
    await this.machinesService.updateMachine(this.machine);
    this.store.dispatch(InstallationActions.updateMachine({ machine: this.machine }));
    this.alertService.alertSuccess('Machine Updated Successfully!');
  }

  async addConveyor() {
    let createConveyor = new Conveyor();
    createConveyor.machineId = this.createdMachineId
      ? this.createdMachineId
      : this.machineSelected.id;

    createConveyor.aliasName = this.conveyorReader.conveyorName;
    createConveyor.description = this.conveyorReader.description;
    createConveyor.diameter = Number(this.conveyorReader.diameter) / 1000;
    if (this.current.containsRfidReader) {
      createConveyor.idleDrumDiameter = null;
    } else {
      createConveyor.idleDrumDiameter = Number(this.conveyorReader.idleDrumDiameter) / 1000;
    }

    const response = await this.conveyorService.addConveyor(createConveyor);
    createConveyor.id = response.id;

    this.store.dispatch(InstallationActions.updateConveyor({ conveyor: createConveyor }));
    this.store.dispatch(InstallationActions.setCurrentConveyor({ conveyor: createConveyor }));

    this.alertService.alertSuccess('Conveyor Created Successfully!');
  }

  async updateConveyor() {
    let createConveyor = new Conveyor();
    createConveyor.id = this.conveyor.id;
    createConveyor.machineId = this.conveyor.machineId;

    createConveyor.aliasName = this.conveyorReader.conveyorName;
    createConveyor.description = this.conveyorReader.description;
    createConveyor.diameter = Number(this.conveyorReader.diameter) / 1000;

    if (this.current.containsRfidReader) {
      createConveyor.idleDrumDiameter = null;
    } else {
      createConveyor.idleDrumDiameter = Number(this.conveyorReader.idleDrumDiameter) / 1000;
    }

    const response = await this.conveyorService.updateConveyor(createConveyor);
    createConveyor.id = response.id;

    this.store.dispatch(InstallationActions.updateConveyor({ conveyor: createConveyor }));
    this.store.dispatch(InstallationActions.setCurrentConveyor({ conveyor: createConveyor }));

    this.alertService.alertSuccess('Conveyor Updated Successfully!');
  }
}
