import jwt_decode from 'jwt-decode';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler } from '@angular/common/http';
import { HttpRequest, HttpInterceptor } from '@angular/common/http';

import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

import { AuthService } from '../services/auth/auth.service';
import { StorageService } from '../services/storage/storage.service';

import { environment } from 'src/environments/environment';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private msalService: MsalService,
    private storageService: StorageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = this.authService.getUser();
    if (!user) return next.handle(request);

    const expired = this.isTokenExpired(user);

    if (expired) {
      return from(this.handleTokenRefresh()).pipe(
        switchMap((newToken: string) => {
          const clonedRequest = this.addTokenHeader(request, newToken);
          return next.handle(clonedRequest);
        })
      );
    } else {
      const clonedRequest = this.addTokenHeader(request, user.token);
      return next.handle(clonedRequest);
    }
  }

  private isTokenExpired(user): boolean {
    if (!user || !user.token) return true;

    try {
      const tokenPayload: any = jwt_decode(user.token);
      const tokenExpiration = tokenPayload?.exp;
      const now = Math.floor(Date.now() / 1000);

      return tokenExpiration ? tokenExpiration < now : true;
    } catch (e) {
      console.error('Failed to decode JWT', e);
      return true;
    }
  }

  private handleTokenRefresh(): Promise<string> {
    return this.msalService
      .acquireTokenSilent({
        scopes: [environment.ammscanApiScope],
        account: this.msalService.instance.getActiveAccount(),
      })
      .toPromise()
      .then(async (result) => {
        await this.updateToken(result.accessToken);
        return result.accessToken;
      })
      .catch((err) => {
        if (err instanceof InteractionRequiredAuthError) {
          this.msalService.acquireTokenRedirect({
            scopes: [environment.ammscanApiScope],
          });
        }
        throw err;
      });
  }

  private addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }

  private async updateToken(token: string) {
    const storedUserData = this.storageService.getUserData();
    const userData = { ...storedUserData, token: token };
    this.storageService.setUserData(userData);
    const user = this.authService.getUser();
    await this.authService.onMsalAuthChange(userData);
  }
}
