import { BrowserCacheLocation } from '@azure/msal-browser';

import { environment } from 'src/environments/environment';
import { MsalScope } from './services/c-belt/msal.service';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const b2cCBeltPolicies = {
  names: {
    signUpSignIn: 'B2C_1_Connected_Belt',
    resetPassword: 'B2C_1_Connected_Belt',
    editProfile: 'B2C_1_Connected_Belt',
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://connectedbelt.b2clogin.com/connectedbelt.onmicrosoft.com/B2C_1_Connected_Belt',
    },
    resetPassword: {
      authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_reset_v3',
    },
    editProfile: {
      authority:
        'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2',
    },
  },
  authorityDomain: 'connectedbelt.b2clogin.com',
};

export const b2cAmmscanPolicies = {
  names: {
    signUpSignIn: 'B2C_1_Ammscan_Sign_In',
    resetPassword: 'B2C_1_Ammscan_Sign_In',
    editProfile: 'B2C_1_Ammscan_Sign_In',
    // signUpSignIn: 'B2C_1_Ammscan',
    // resetPassword: 'B2C_1_Ammscan',
    // editProfile: 'B2C_1_Ammscan',
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://connectedbelt.b2clogin.com/connectedbelt.onmicrosoft.com/B2C_1_Ammscan_Sign_In',
        // 'https://connectedbelt.b2clogin.com/connectedbelt.onmicrosoft.com/B2C_1_Ammscan',
    },
    resetPassword: {
      authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_reset_v3',
    },
    editProfile: {
      authority:
        'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2',
    },
  },
  authorityDomain: 'connectedbelt.b2clogin.com',
};

export default class AuthMsalConfig {
  static fetchConfig(scope: MsalScope) {
    const userAgent = navigator.userAgent || navigator.vendor;
    const cBeltClientId = environment.cBeltClientId;
    const ammscanClientId = environment.ammscanClientId;
    const cBeltRedirectUri = environment.cBeltRedirectUri;
    const loginRedirectUri = environment.loginRedirectUri;
    const logoutRedirectUri = environment.logoutRedirectUri;

    const isAndroid = /android/i.test(userAgent);
    const policies = scope == MsalScope.cBelt ? b2cCBeltPolicies : b2cAmmscanPolicies;

    return {
      auth: {
        clientId: isAndroid ? cBeltClientId : ammscanClientId,
        authority: policies.authorities.signUpSignIn.authority,
        knownAuthorities: [policies.authorityDomain],
        redirectUri: isAndroid ? cBeltRedirectUri : loginRedirectUri,
        postLogoutRedirectUri: isAndroid ? cBeltRedirectUri : logoutRedirectUri,
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
      },
      system: {
        allowNativeBroker: false,
      },
    };
  }
}
