import { FormsModule } from '@angular/forms';
import { NgOptimizedImage } from '@angular/common';
import { NgModule, isDevMode } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppstraxServices } from '@appstrax/services';
import { NFC } from '@awesome-cordova-plugins/nfc/ngx';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { MsalModule, MsalBroadcastService } from '@azure/msal-angular';
import { MsalInterceptor, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { environment } from './../environments/environment';
import { SortableHeaderDirective } from './shared/directives/sortable.directive';

import { UtilService } from './utils/util.service';
import { ApiService } from './services/api/api.service';
import { AuthService } from './services/auth/auth.service';
import { SyncService } from './services/sync/sync.service';
import { ModalService } from './services/modal/modal.service';
import { SurveyService } from './services/survey/survey.service';
import { CBeltApiService } from './services/api/cBeltApi.service';
import { MappingService } from './services/mapping/mapping.service';
import { StorageService } from './services/storage/storage.service';
import { AmmscanApiService } from './services/api/ammscanApi.service';
import { CustomerService } from './services/customer/customer.service';
import { OnlineService } from './services/online-service/online.service';
import { OrganizationsService } from './services/c-belt/organizations.service';
import { FetchService } from './services/sync/fetch.service';

import { CscEffects } from './state/csc/csc.effects';
import { BeltEffects } from './state/belt/belt.effects';
import { UserEffects } from './state/user/user.effects';

import { metaReducers, reducers } from './state/core.reducer';
import { SurveyEffects } from './state/survey/survey.effects';
import { CustomerEffects } from './state/customer/customer.effects';
import { InstallationEffects } from './state/installation/installation.effects';

import AuthMsalConfig from './auth-config';
import { MsalScope } from './services/c-belt/msal.service';
import { CBeltAuthGuard } from './guards/c-belt-auth.guard';
import { AuthHttpInterceptor } from './utils/http-interceptor';

import { AppComponent } from './app.component';
import { NavComponent } from './components/sidebar/nav/nav.component';
import { CBeltComponent } from './components/c-belt/c-belt.component';
import { LanguageComponent } from './pages/language/language.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SwitchComponent } from './shared/components/switch/switch.component';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { DeleteModalComponent } from './modals/delete-modal/delete-modal.component';
import { TranslationsComponent } from './pages/translations/translations.component';
import { BeltEditComponent } from './components/belts/belt-edit/belt-edit.component';
import { BeltListComponent } from './components/belts/belt-list/belt-list.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { BeltInputComponent } from './components/belts/belt-input/belt-input.component';
import { FilterButtonComponent } from './components/filter-button/filter-button.component';
import { BeltCreateComponent } from './components/belts/belt-create/belt-create.component';
import { BeltImportComponent } from './components/belts/belt-import/belt-import.component';
import { ScanSensorComponent } from './components/c-belt/scan-sensor/scan-sensor.component';
import { SurveyListComponent } from './components/surveys/survey-list/survey-list.component';
import { ScanningComponent } from './components/c-belt/scanning/scanning/scanning.component';
import { InstallButtonComponent } from './components/install-button/install-button.component';
import { AddCustomerComponent } from './components/surveys/add-customer/add-customer.component';
import { CbeltListComponent } from './components/c-belt/installing/cbelt-list/cbelt-list.component';
import { EditSensorComponent } from './components/c-belt/installing/edit-sensor/edit-sensor.component';
import { AddSensorsComponent } from './components/c-belt/installing/add-sensors/add-sensors.component';
import { SystemSetupComponent } from './components/c-belt/installing/system-setup/system-setup.component';
import { ScanningRfidComponent } from './components/c-belt/fitting/scanning-rfid/scanning-rfid.component';
import { InspectionListComponent } from './components/inspections/inspection-list/inspection-list.component';
import { SelectCustomerComponent } from './components/c-belt/shared/select-customer/select-customer.component';
import { ConfigureWifiComponent } from './components/c-belt/installing/configure-wifi/configure-wifi.component';
import { MobileSurveyListComponent } from './components/surveys/mobile-survey-list/mobile-survey-list.component';
import { InstallingHomeComponent } from './components/c-belt/installing/installing-home/installing-home.component';
import { MobileBeltListComponent } from './components/belts/belt-list/mobile-belt-list/mobile-belt-list.component';
import { DesktopBeltListComponent } from './components/belts/belt-list/desktop-belt-list/desktop-belt-list.component';
import { NewGatewayDeviceComponent } from './components/c-belt/installing/new-gateway-device/new-gateway-device.component';
import { ConfiguringDeviceComponent } from './components/c-belt/installing/configuring-device/configuring-device.component';
import { CBeltInstallationComponent } from './components/c-belt/installing/c-belt-installation/c-belt-installation.component';
import { CBeltConfigurationComponent } from './components/c-belt/fitting/c-belt-configuration/c-belt-configuration.component';
import { AddSensorManuallyComponent } from './components/c-belt/installing/add-sensor-manually/add-sensor-manually.component';
import { SelectConfigurationComponent } from './components/c-belt/modifying/select-configuration/select-configuration.component';
import { SystemSetupSensorsComponent } from './components/c-belt/installing/system-setup-sensors/system-setup-sensors.component';
import { PairConveyorReaderComponent } from './components/c-belt/installing/pair-conveyor-reader/pair-conveyor-reader.component';
import { SensorConfigurationComponent } from './components/c-belt/installing/sensor-configuration/sensor-configuration.component';
import { GatewayConfigurationComponent } from './components/c-belt/installing/gateway-configuration/gateway-configuration.component';
import { SystemSetupPairRfidComponent } from './components/c-belt/installing/system-setup-pair-rfid/system-setup-pair-rfid.component';
import { PairConveyorAndBeltComponent } from './components/c-belt/installing/pair-conveyor-and-belt/pair-conveyor-and-belt.component';
import { ConfigurationOverviewComponent } from './components/c-belt/installing/configuration-overview/configuration-overview.component';
import { BeltValidationComponent } from './components/c-belt/installing/configuration-overview/belt-validation/belt-validation.component';
import { ConfiguringDeviceFourGComponent } from './components/c-belt/installing/configuring-device-four-g/configuring-device-four-g.component';
import { SensorValidationComponent } from './components/c-belt/installing/configuration-overview/sensor-validation/sensor-validation.component';
import { CalibrateConveyorReaderComponent } from './components/c-belt/installing/calibrate-conveyor-reader/calibrate-conveyor-reader.component';
import { MobileBeltItemCardComponent } from './components/belts/belt-list/mobile-belt-list/mobile-belt-item-card/mobile-belt-item-card.component';
import { GatewayValidationComponent } from './components/c-belt/installing/configuration-overview/gateway-validation/gateway-validation.component';
import { CompanyValidationComponent } from './components/c-belt/installing/configuration-overview/company-validation/company-validation.component';
import { ConveyorValidationComponent } from './components/c-belt/installing/configuration-overview/conveyor-validation/conveyor-validation.component';
import { PairConveyorReaderManuallyComponent } from './components/c-belt/installing/pair-conveyor-reader-manually/pair-conveyor-reader-manually.component';
import { ConveyorReaderConfigurationComponent } from './components/c-belt/installing/conveyor-reader-configuration/conveyor-reader-configuration.component';
import { SelectConfigurationPairRfidComponent } from './components/c-belt/modifying/select-configuration-pair-rfid/select-configuration-pair-rfid.component';
import { ConveyorReaderValidationComponent } from './components/c-belt/installing/configuration-overview/conveyor-reader-validation/conveyor-reader-validation.component';
import { AddRfidManuallyComponent } from './components/c-belt/installing/add-rfid-manually/add-rfid-manually.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { InfoModalComponent } from './modals/info-modal/info-modal.component';

AppstraxServices.init({ storageUrl: environment.storageApiBaseUrl })
  .then(() => console.log('Services initialized'))
  .catch((err) => console.log(err));

const isMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor;
  return /android/i.test(userAgent);
};

const httpInterceptor = isMobileOperatingSystem() ? AuthHttpInterceptor : MsalInterceptor;

@NgModule({
  declarations: [
    AddCustomerComponent,
    AddSensorManuallyComponent,
    AddSensorsComponent,
    AppComponent,
    AppLayoutComponent,
    AppLayoutComponent,
    BeltCreateComponent,
    BeltEditComponent,
    BeltImportComponent,
    BeltInputComponent,
    BeltListComponent,
    BeltValidationComponent,
    CalibrateConveyorReaderComponent,
    CBeltComponent,
    CBeltConfigurationComponent,
    CBeltInstallationComponent,
    CompanyValidationComponent,
    ConfigurationOverviewComponent,
    ConfigureWifiComponent,
    ConfiguringDeviceComponent,
    SystemSetupComponent,
    ConveyorReaderConfigurationComponent,
    SelectConfigurationComponent,
    PairConveyorReaderComponent,
    PairConveyorAndBeltComponent,
    PairConveyorReaderManuallyComponent,
    ConfiguringDeviceFourGComponent,
    AddSensorsComponent,
    ScanSensorComponent,
    SensorConfigurationComponent,
    SystemSetupSensorsComponent,
    SystemSetupPairRfidComponent,
    CbeltListComponent,
    SelectConfigurationPairRfidComponent,
    AddSensorManuallyComponent,
    EditSensorComponent,
    ConfigurationOverviewComponent,
    CompanyValidationComponent,
    BeltValidationComponent,
    ConfiguringDeviceFourGComponent,
    ConfirmModalComponent,
    InfoModalComponent,
    ConveyorReaderConfigurationComponent,
    ConveyorReaderValidationComponent,
    ConveyorValidationComponent,
    DeleteModalComponent,
    DesktopBeltListComponent,
    EditSensorComponent,
    FilterButtonComponent,
    AddRfidManuallyComponent,
    GatewayConfigurationComponent,
    GatewayValidationComponent,
    InspectionListComponent,
    InspectionListComponent,
    InstallButtonComponent,
    InstallingHomeComponent,
    LanguageComponent,
    MobileBeltItemCardComponent,
    MobileBeltListComponent,
    MobileSurveyListComponent,
    NavComponent,
    NewGatewayDeviceComponent,
    PairConveyorAndBeltComponent,
    PairConveyorReaderComponent,
    PairConveyorReaderManuallyComponent,
    ScanningComponent,
    ScanningRfidComponent,
    ScanSensorComponent,
    SelectConfigurationComponent,
    SelectCustomerComponent,
    SensorConfigurationComponent,
    SensorValidationComponent,
    SidebarComponent,
    SidebarComponent,
    SortableHeaderDirective,
    SortableHeaderDirective,
    SurveyListComponent,
    SwitchComponent,
    TranslationsComponent,
    LogoutComponent,
  ],
  imports: [
    BsDatepickerModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    SharedModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    EffectsModule.forRoot([
      CscEffects,
      BeltEffects,
      UserEffects,
      SurveyEffects,
      CustomerEffects,
      InstallationEffects,
    ]),
    MsalModule.forRoot(
      new PublicClientApplication(AuthMsalConfig.fetchConfig(MsalScope.surveys)),
      { interactionType: InteractionType.Redirect },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [`${environment.baseUrl}/*`, [environment.ammscanApiScope]],
          [`${environment.cBeltApi}/*`, null],
        ]),
      }
    ),
    NgOptimizedImage,
  ],
  providers: [
    ApiService,
    CBeltApiService,
    AmmscanApiService,
    AuthService,
    BsDatepickerConfig,
    CustomerService,
    MappingService,
    ModalService,
    OnlineService,
    SyncService,
    FetchService,
    StorageService,
    OrganizationsService,
    SurveyService,
    UtilService,
    NFC,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    CBeltAuthGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
