import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Survey } from './../../../models/survey.model';

import { TranslateService } from './../../../services/translate.service';

@Component({
  selector: 'app-mobile-survey-list',
  templateUrl: './mobile-survey-list.component.html',
  styleUrl: './mobile-survey-list.component.scss',
})
export class MobileSurveyListComponent {
	@Input() surveys: Survey[] = [];
	@Output() addSurveyClick: EventEmitter<any> = new EventEmitter<any>();
	@Output() removeSurveyClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(public t: TranslateService) {}

	onAddSurveyClick() {
		this.addSurveyClick.emit();
	}

  public createMenuItems(survey: Survey) {
    return [
      {
        title: `${this.t.translate('_REMOVE')}`,
        symbol: 'delete_forever',
        onClick: () => {
          this.removeSurveyClick.emit(survey);
        },
      },
    ];
  }
}
