import { cloneDeep } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';

import { Gateway } from 'src/app/models/gateway.model';

import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { BluetoothLeService } from 'src/app/services/c-belt/bluetooth-le.service';

import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-configuring-device',
  templateUrl: './configuring-device.component.html',
  styleUrls: ['./configuring-device.component.scss'],
})
export class ConfiguringDeviceComponent implements OnInit, OnDestroy {
  baseRoute: string;
  companyId: string;
  gatewayDeviceId: string;
  messages: string[] = [];
  loading: boolean = false;
  gatewayDevice: Gateway = null;

  private gatewaySub: Subscription;

  constructor(
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private bluetoothleService: BluetoothLeService
  ) {}

  ngOnInit() {
    this.route.url.subscribe((route) => {
      this.baseRoute = route[0].path;
    });
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.gatewayDeviceId = params['gatewayDeviceId'];
      this.subscribeToGatewayDevice(params['gatewayDeviceId']);
    });
    this.setupBTDevice();
  }

  ngOnDestroy(): void {
    if (this.gatewaySub) this.gatewaySub.unsubscribe();
    this.stopAdvertising();
  }

  subscribeToGatewayDevice(id: string) {
    const select = InstallationSelectors.selectCurrentGateway();
    this.gatewaySub = this.store.select(select).subscribe((gateway) => {
      this.gatewayDevice = cloneDeep(gateway);
      console.log('On Advertising page: ', JSON.stringify(this.gatewayDevice));
    });
  }

  async setupBTDevice() {
    try {
      await this.initializeBT();
      await this.checkPermissions();
      await this.startAdvertising();
    } catch (err) {
      this.alertService.alertError('Error setting up bluetooth');
    }
  }

  async initializeBT(): Promise<void> {
    const enabled = await this.bluetoothleService.initialize();
    if (!enabled) await this.enableBT();
  }

  async enableBT() {
    const res = await this.bluetoothleService.isEnabled();
    if (!res.isEnabled) {
      const res = await this.bluetoothleService.enable();
      if (!res.status) throw new Error('Please enable bluetooth');
    }
  }

  async checkPermissions() {
    const permission = await this.bluetoothleService.hasConnectPermission();
    if (!permission) await this.bluetoothleService.requestConnectPermission();
    const hasAdvertize = await this.bluetoothleService.hasAdvertizePermission();
    if (!hasAdvertize) await this.bluetoothleService.requestAdvertizePermission();
  }

  startAdvertising() {
    this.loading = true;
    window['plugins'].blePeripheral.startAdvertising(
      (res) => this.ngZone.run(() => this.onAdvertisingCommunicate(res)),
      (err) => this.ngZone.run(() => this.onAdvertisingError(err)),
      this.stringifyData()
    );
  }

  onAdvertisingCommunicate(res) {
    if (res == 'Write 0xF0 Successful') {
      this.messages.push(res);
      this.messages.push('Gateway Configured');
      this.stopAdvertising();
      return;
    }
    if (res == 'Central Device Disconnected') {
      this.messages.push(res);
      this.stopAdvertising();
      return;
    }
    this.messages.push(res);
  }

  stringifyData() {
    if (this.gatewayDevice.dns) {
      return JSON.stringify({
        type: 'static',
        ssid: this.gatewayDevice.networkName,
        password: this.gatewayDevice.devicePassword,
        ipAddress: this.gatewayDevice.ipAddress,
        gateway: this.gatewayDevice.gatewayAddress,
        dns: this.gatewayDevice.dns,
        deviceId: this.gatewayDevice.gatewayDeviceId,
      });
    } else {
      return JSON.stringify({
        type: 'dhcp',
        ssid: this.gatewayDevice.networkName,
        password: this.gatewayDevice.devicePassword,
        deviceId: this.gatewayDevice.gatewayDeviceId,
      });
    }
  }

  async stopAdvertising(): Promise<void> {
    window['plugins'].blePeripheral.stopAdvertising(
      (message) => this.ngZone.run(() => this.onAdvertisingStopped(message)),
      (err) => this.ngZone.run(() => this.onAdvertisingError(err))
    );
  }

  onAdvertisingStopped(message: string) {
    this.loading = false;
    this.alertService.alertSuccess(message);
  }

  onAdvertisingError(err) {
    console.log('Cameron: ', JSON.stringify(err));
  }

  navToSteps() {
    this.router.navigate([this.baseRoute, this.companyId]);
  }
}
