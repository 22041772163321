import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';

import { Sensor } from '../../..//models/sensor.model';
import { Customer } from '../../../models/customer.model';
import { Conveyor } from '../../../models/conveyor.model';
import { CBeltMachine } from '../../../models/cBeltMachine.model';
import { PairedSensor } from '../../../models/paired-sensor.model';

import { TranslateService } from './../../../services/translate.service';
import { SensorsService } from '../../../../app/services/c-belt/sensors.service';
import { AlertService } from '../../../../app/shared/services/alert/alert.service';
import { MachinesService } from '../../../../app/services/c-belt/machines.service';
import { LoadingService } from './../../../shared/services/loading/loading.service';
import { ConveyorsService } from '../../../../app/services/c-belt/conveyors.service';
import { MsalCBeltService, MsalScope } from '../../../../app/services/c-belt/msal.service';
import { OrganizationsService } from '../../../../app/services/c-belt/organizations.service';

import { Subscription } from 'rxjs';
import { NFC } from '@awesome-cordova-plugins/nfc/ngx';

@Component({
  selector: 'app-scan-sensor',
  templateUrl: './scan-sensor.component.html',
  styleUrl: './scan-sensor.component.scss',
})
export class ScanSensorComponent implements OnInit, OnDestroy {
  edid: any;
  enabled: boolean;
  manualEdid: number;

  sensor: Sensor = new Sensor();
  conveyor: Conveyor = new Conveyor();
  customer: Customer = new Customer();
  machine: CBeltMachine = new CBeltMachine();
  pairedSensor: PairedSensor = new PairedSensor();

  pairedSensors: PairedSensor[] = [];

  private scannerSub: Subscription;

  qrConfig = {
    preferFrontCamera: false,
    showFlipCameraButton: true,
    showTorchButton: true,
    torchOn: false,
    saveHistory: true,
    prompt: 'Place a barcode inside the scan area',
    resultDisplayDuration: 500,
    formats: 'QR_CODE,PDF_417',
    orientation: 'portrait',
    disableAnimations: true,
    disableSuccessBeep: false,
  };

  constructor(
    private nfc: NFC,
    private ngZone: NgZone,
    private alertService: AlertService,
    private sensorService: SensorsService,
    private loadingService: LoadingService,
    private machineService: MachinesService,
    private conveyorService: ConveyorsService,
    private msalCBeltService: MsalCBeltService,
    private organizationService: OrganizationsService,
    public t: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.loadingService.show();
    if (this.isMobileOperatingSystem()) {
      await this.msalCBeltService.msalInit(MsalScope.cBelt);
      await this.msalCBeltService.cBeltLogin();
    }
    this.addListener();
    this.loadingService.hide();
  }

  async ngOnDestroy(): Promise<void> {
    if (this.scannerSub) {
      this.scannerSub.unsubscribe();
    }
  }

  private isMobileOperatingSystem(): boolean {
    var userAgent = navigator.userAgent || navigator.vendor;
    return /android/i.test(userAgent);
  }

  async addListener() {
    this.alertService.alertInfo('Attempting to Scan');
    this.nfc.addNdefListener().subscribe(
      (tag) => this.ngZone.run(() => this.onTagFound(tag)),
      (err) => this.ngZone.run(() => this.onNfcError(err))
    );
  }

  onTagFound(tag) {
    let payload = tag['tag']['ndefMessage'][0]['payload'];
    let stringValue = this.nfc.bytesToString(payload);
    let hexValue = stringValue.slice(3, 20);
    this.edid = this.macAddressToDecimal(hexValue);
    this.findSensorByEdid(this.edid);
  }

  onNfcError(err) {
    if (err == 'NFC_DISABLED') {
      this.enabled = false;
      this.alertService.alertError('Please Enable NFC');
    }
  }

  openAndScanQR() {
    window['cordova'].plugins['barcodeScanner'].scan(
      (result) => this.ngZone.run(() => (this.edid = result.text)),
      (err) => this.ngZone.run(() => this.onQRScanError(err)),
      this.qrConfig
    );
  }

  onQRScanError(err) {
    this.alertService.alertError('QR Scan Failed: ' + JSON.stringify(err));
  }

  macAddressToDecimal(macAddress: string): number {
    const macParts = macAddress.split(':');
    let decimalNumber = 0;

    for (let i = 0; i < macParts.length; i++) {
      const hexPart = parseInt(macParts[i], 16);
      decimalNumber = decimalNumber * 256 + hexPart;
    }

    return decimalNumber;
  }

  manualSearch() {
    this.edid = this.manualEdid.toString();
    this.findSensorByEdid(this.manualEdid.toString());
    this.manualEdid = null;
  }

  async findSensorByEdid(edid: string): Promise<void> {
    this.loadingService.show();
    let sensor: Sensor;

    const result = await this.sensorService.getSensorsByEdid(edid);

    if (!result.length) {
      this.alertService.alertError(`No Sensors with EDID: ${edid}`);
      this.sensor = null;
      this.loadingService.hide();
    } else {
      sensor = result[0];
      this.sensor = result[0];
      this.findConveyorById(sensor.conveyorId);
    }
  }

  async findConveyorById(id: string): Promise<void> {
    const conveyor: Conveyor = await this.conveyorService.getConveyorById(id);

    if (!conveyor) {
      this.alertService.alertError(`No Conveyor for Sensor`);
      this.conveyor = null;
      this.loadingService.hide();
    } else {
      this.conveyor = conveyor;
      this.findMachineById(conveyor.machineId);
    }
  }

  async findMachineById(id: string): Promise<void> {
    const machine: CBeltMachine = await this.machineService.getMachineById(id);

    if (!machine) {
      this.alertService.alertError(`No Machine for Conveyor`);
      this.machine = null;
      this.loadingService.hide();
    } else {
      this.machine = machine;
      this.findCustomerById(machine.organizationId);
    }
  }

  async findCustomerById(id: string): Promise<void> {
    const customer: Customer = await this.organizationService.getCustomerById(id);

    if (!customer) {
      this.alertService.alertError(`No Customer for Machine`);
      this.customer = null;
    } else {
      this.customer = customer;
      this.fetchPairedSensors();
    }
    this.loadingService.hide();
  }

  async fetchPairedSensors(): Promise<void> {
    const sensors: PairedSensor[] = await this.sensorService.getPairedSensors();

    if (!sensors) {
      this.alertService.alertError(`No Paired Sensors`);
      this.pairedSensors = [];
      this.pairedSensor = null;
    } else {
      this.pairedSensors = sensors;
      this.pairedSensor = this.pairedSensors.find((x) => x.edid === this.sensor.edid);
    }
    this.loadingService.hide();
  }

  formatDateTime(date: string) {
    return new Date(date).toLocaleString();
  }

  newScan() {
    this.edid = null;
    this.sensor = null;
    this.conveyor = null;
    this.machine = null;
    this.customer = null;
    this.pairedSensor = null;
    this.pairedSensors = [];
  }
}
