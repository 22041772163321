import { appInit } from '../core.actions';
import { Injectable } from '@angular/core';

import { map } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as InstallationActions from './installation.actions';
import { StateService } from 'src/app/services/c-belt/state.service';

@Injectable()
export class InstallationEffects {
  constructor(private actions$: Actions<any>, private stateService: StateService) {}

  appInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appInit),
      map(() => {
        const state = this.stateService.loadCurrentFromLocalStorage();
        return InstallationActions.setCurrentState({ current: state });
      })
    )
  );
}
