import { Component } from '@angular/core';

import { Subscription } from 'rxjs';

import { AlertService } from '../../shared/services/alert/alert.service';
import { LanguageFlags, TranslateService } from '../../services/translate.service';
import { BreadcrumbService } from './../../shared/services/breadcrumb/breadcrumb.service';
import { Breadcrumb } from '../../shared/services/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrl: './language.component.scss',
})
export class LanguageComponent {
  sub: Subscription;
  languageKey: any;
  languages: any[] = [];

  constructor(
    private alertService: AlertService,
    public t: TranslateService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.languageKey = this.t.getLanguageKey();
    this.subscribeToLanguages();

    this.initBreadcrumbs();
  }

  ngOnDestroy() {
    this.t.unsubscribeFromLanguageChanges(this.sub);
  }

  private subscribeToLanguages() {
    this.sub = this.t.subscribeToLanguageChanges((languages) => {
      if (!languages.length) return;
      this.languageKey = this.t.languageKey;

      languages = languages.map((language) => ({
        ...language,
        country_flag: LanguageFlags[language.key],
      }));
      this.languages = languages;

      if (!this.languageKey) {
        this.languageKey = this.languages[0].key;
        this.setLanguage();
      }
    });
  }

  private initBreadcrumbs() {
    this.breadcrumbService.setBreadcrumbs([]);
    // const crumb = new Breadcrumb('Languages', ['/', 'languages']);
    // this.breadcrumbService.pushBreadcrumb(crumb);
  }

  onSelectLanguage(languageKey) {
    this.languageKey = languageKey;
    this.setLanguage();
  }

  setLanguage() {
    const language = this.languages.find((lang) => lang.key === this.languageKey);
    if (!language) return;
    if (!this.languageKey) {
      this.languageKey = language.key;
    }

    this.t.setLanguage(language);

    this.alertService.alertSuccess(this.t.translate('_SAVE_SUCCESS'));
  }
}
