import { cloneDeep } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Customer } from 'src/app/models/customer.model';

import { StateService } from 'src/app/services/c-belt/state.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-c-belt-installation',
  templateUrl: './c-belt-installation.component.html',
  styleUrls: ['./c-belt-installation.component.scss'],
})
export class CBeltInstallationComponent implements OnInit {
  editing: boolean;
  companyId: string;
  customer: Customer;
  current: ConfigurationState = null;
  containsRfidReader: boolean = true;
  loading: boolean = false;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private stateService: StateService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToCurrentConfiguration();
    });
  }

  toggleRfidReader(event: any) {
    this.containsRfidReader = event.target.checked;
    this.store.dispatch(
      InstallationActions.updateContainsRfidStatus({
        containsRfidReader: this.containsRfidReader,
      })
    );
  }

  subscribeToCurrentConfiguration() {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.customer = this.current.company;
        this.containsRfidReader = this.current.containsRfidReader;
        this.editing = this.current.editing;
      });
  }

  returnTitle() {
    return `CBLT - ${this.customer.customer_name}`;
  }

  navToConfigurationOverview() {
    this.stateService.subscribeToCurrentAndSave();
    this.router.navigate(['/installing', this.companyId, 'configuration-overview']);
  }

  navToConveyorCalibration() {
    if (!this.current.conveyors.length) {
      this.alertService.alertError('Must have a Conveyor Setup first');
      return;
    }
    this.router.navigate(['/installing', this.companyId, 'calibrate-conveyor']);
  }

  navToSensorConfiguration() {
    if (!this.current.conveyors.length) {
      this.alertService.alertError('Must have a Conveyor Setup first');
      return;
    }
    this.router.navigate(['/installing', this.companyId, 'system-setup-sensors']);
  }
}
