import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from "@angular/core";
import { RouterModule } from '@angular/router';

import { AlertService } from './services/alert/alert.service';
import { BreadcrumbService } from './services/breadcrumb/breadcrumb.service';
import { LoadingService } from './services/loading/loading.service';

import { AlertComponent } from './components/alert/alert.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { DesktopHeaderComponent } from './components/desktop-header/desktop-header.component';
import { ImageInputComponent } from './components/image-input/image-input.component';
import { InputComponent } from './components/input/input.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MetaMenuComponent } from './components/meta-menu/meta-menu.component';
import { MobileHeaderComponent } from './components/mobile-header/mobile-header.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { TableComponent } from './components/table/table.component';
import { TextareaComponent } from './components/textarea/textarea.component';

@NgModule({
    declarations: [
        AlertComponent,
        BreadcrumbsComponent,
        DesktopHeaderComponent,
        ImageInputComponent,
        InputComponent,
        LoadingComponent,
        MetaMenuComponent,
        MobileHeaderComponent,
        SearchInputComponent,
        TableComponent,
        TextareaComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
    ],
    providers: [
        AlertService,
        BreadcrumbService,
        LoadingService
    ],
    exports: [
        AlertComponent,
        BreadcrumbsComponent,
        DesktopHeaderComponent,
        ImageInputComponent,
        InputComponent,
        LoadingComponent,
        MetaMenuComponent,
        MobileHeaderComponent,
        SearchInputComponent,
        TableComponent,
        TextareaComponent,
    ]
})
export class SharedModule { }
