import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';

import { filter, firstValueFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { setTheme } from 'ngx-bootstrap/utils';

import { environment } from './../environments/environment';
import { appInit } from './state/core.actions';
import * as UserActions from './state/user/user.actions';

import { SyncService } from './services/sync/sync.service';
import { AuthService } from './services/auth/auth.service';
import { ForageService } from './services/forage/forage.service';
import { AlertService } from './shared/services/alert/alert.service';
import { MsalCBeltService, MsalScope } from './services/c-belt/msal.service';
import { TranslateContext, TranslateService } from './services/translate.service';
import { StorageService } from './services/storage/storage.service';
import { LoadingService } from './shared/services/loading/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loading: boolean = false;

  constructor(
    private store: Store,
    private router: Router,
    private t: TranslateService,
    private syncService: SyncService,
    private authService: AuthService,
    private msalService: MsalService,
    private alertService: AlertService,
    private forageService: ForageService,
    private loadingService: LoadingService,
    private storageService: StorageService,
    private msalCBeltService: MsalCBeltService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    setTheme('bs5');
    this.addClarityTrackingCodeToHead();
  }

  async ngOnInit(): Promise<void> {
    this.loadingService.show();
    try {
      await this.t.init();
      this.t.fetchTranslations(TranslateContext.common);

      await firstValueFrom(this.msalService.initialize());

      if (this.isMobileOperatingSystem()) {
        await this.initMobile();
      } else {
        this.listenForBroadcastUpdates();
      }

      const userData = this.storageService.getUserData();
      if (userData) await this.authService.onMsalAuthChange(userData);

      this.store.dispatch(appInit());
      await this.forageService.init();
      await this.syncService.init();

      const user = this.authService.getUser();
      if (user) this.router.navigate(['surveys']);
    } catch (error) {
      console.error('Error during MSAL initialization:', error);
    }
    this.loadingService.hide();
  }

  private isMobileOperatingSystem(): boolean {
    var userAgent = navigator.userAgent || navigator.vendor;
    return /android/i.test(userAgent);
  }

  private async initMobile() {
    this.loading = true;
    try {
      await this.msalCBeltService.msalInit(MsalScope.cBelt);
      await this.msalCBeltService.cBeltLogin();
      this.router.navigate(['surveys']);
    } catch (err) {
      this.alertService.handleCBeltError(err);
    }
    this.loading = false;
  }

  private listenForBroadcastUpdates() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        )
      )
      .subscribe(async (result: EventMessage) => {
        try {
          const payload = result.payload as AuthenticationResult;
          const userData = {
            id: payload.uniqueId,
            email: payload.account.username,
            token: payload.accessToken,
          };

          this.storageService.setUserData(userData);
          this.msalService.instance.setActiveAccount(payload.account);
          await this.authService.onMsalAuthChange(userData);
        } catch (err) {
          this.alertService.handleHttpError(err);
          console.log(err);
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGOUT_SUCCESS))
      .subscribe((msg) => {
        this.store.dispatch(UserActions.logoutSuccess());
        this.msalService.instance.setActiveAccount(null);
        this.authService.onMsalAuthChange(null);
      });
  }

  private addClarityTrackingCodeToHead() {
    const head = document.getElementsByTagName('head')[0];
    const isProduction = environment.production;

    if (isProduction) {
      const script = document.createElement('script');
      script.innerHTML = `
      (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "j1wofi2ghz");
      `;
      head.insertBefore(script, head.lastChild);
    }
  }
}
