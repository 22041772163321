<div class="mobile">
  <div class="px-2">
    <h2 class="text-center header mt-3">Scan Sensor</h2>
  </div>

  <div class="mx-3">
    <div class="bg-white card p-3 mt-4" *ngIf="!edid">
      <div>Search Sensor via NFC</div>
      <div class="grey mt-2">Hold the top of your phone against the sensor to scan its NFC tag</div>
      <div class="grey mt-2">
        Please ensure NFC is enabled on your device, once activated navigate back to this screen to
        scan.
      </div>
    </div>

    <form name="configuration-form" class="d-flex mt-3" *ngIf="!edid">
      <div class="bg-white card p-3 mb-3">
        <div>Search Sensor via QR code</div>
        <div class="grey mt-2">Scan the QR code on top of the box the device came in</div>
        <button type="button" class="btn btn-primary mt-3" (click)="openAndScanQR()">
          SCAN QR
        </button>
      </div>
    </form>

    <form name="enable-nfc-form" class="d-flex mt-3" *ngIf="enabled != null && !enabled">
      <div class="bg-white card p-3">
        <div>NFC is disabled, please enable NFC then click 'Start Scan'</div>
        <button type="button" class="btn btn-primary mt-3" (click)="addListener()">
          Start Scan
        </button>
      </div>
    </form>

    <div class="bg-white card p-3" *ngIf="!edid">
      <div>Manually Enter Sensor EDID</div>
      <div class="grey mt-2">This unique ID can be found on the box the sensor comes in</div>
      <input
        [(ngModel)]="manualEdid"
        class="form-control mt-2"
        name="sensorId"
        type="number"
        placeholder="eg: 34735693"
      />
      <button type="button" class="btn btn-primary mt-3" (click)="manualSearch()">Search</button>
    </div>
  </div>

  <div class="mx-3" *ngIf="edid">
    <div class="bg-white text-center card p-3 mt-4">
      <div>EDID: {{ edid }}</div>
      <button type="button" class="btn btn-primary mt-3" (click)="newScan()">New Scan</button>
    </div>
  </div>
</div>

<div class="mx-3 mt-2">
  <div *ngIf="edid && !sensor">
    <div class="bg-white card p-3">
      <div>No Sensor Data Available</div>
      <div *ngIf="!conveyor">No Conveyor Data Available</div>
      <div *ngIf="!machine">No Machine Data Available</div>
      <div *ngIf="!customer">No Customer Data Available</div>
      <div *ngIf="!pairedSensor">No Paired Sensor Data Available</div>
    </div>
  </div>
</div>

<div *ngIf="edid && sensor">
  <div class="mx-3 mt-2">
    <div class="bg-white card p-3 mt-4">
      <div class="d-flex align-items-center mb-2">
        <div>Sensor Details:</div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Sensor ID:
          <span class="grey">
            {{ sensor.id ? sensor.id : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Conveyor ID:
          <span class="grey">
            {{ sensor.conveyorId ? sensor.conveyorId : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Sensor Nickname:
          <span class="grey">
            {{ sensor.sensorNickname ? sensor.sensorNickname : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Description:
          <span class="grey">
            {{ sensor.description ? sensor.description : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Sensor SID:
          <span class="grey">
            {{ sensor.sid ? sensor.sid : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Sensor EDID:
          <span class="grey">
            {{ sensor.edid ? sensor.edid : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Role:
          <span class="grey">
            {{ sensor.role ? sensor.role : "N/A" }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="edid && sensor && conveyor">
  <div class="mx-3 mt-1">
    <div class="bg-white card p-3 mt-2">
      <div class="d-flex align-items-center mb-2">
        <div>Conveyor Details:</div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Conveyor ID:
          <span class="grey">
            {{ conveyor.id ? conveyor.id : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Machine ID:
          <span class="grey">
            {{ conveyor.machineId ? conveyor.machineId : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Alias Name:
          <span class="grey">
            {{ conveyor.aliasName ? conveyor.aliasName : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Description:
          <span class="grey">
            {{ conveyor.description ? conveyor.description : "N/A" }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="edid && sensor && conveyor && machine">
  <div class="mx-3 mt-1">
    <div class="bg-white card p-3 mt-2">
      <div class="d-flex align-items-center mb-2">
        <div>Machine Details:</div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Machine ID:
          <span class="grey">
            {{ machine.id ? machine.id : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Machine Name:
          <span class="grey">
            {{ machine.machineName ? machine.machineName : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Alias Name:
          <span class="grey">
            {{ machine.aliasName ? machine.aliasName : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Organization ID:
          <span class="grey">
            {{ machine.organizationId ? machine.organizationId : "N/A" }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="edid && sensor && conveyor && machine && customer">
  <div class="mx-3 mt-1">
    <div class="bg-white card p-3 mt-2">
      <div class="d-flex align-items-center mb-2">
        <div>Customer Details:</div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Customer ID:
          <span class="grey">
            {{ customer.id ? customer.id : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Customer Name:
          <span class="grey">
            {{ customer.customer_name ? customer.customer_name : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Customer Number:
          <span class="grey">
            {{ customer.customerNumber ? customer.customerNumber : "N/A" }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="edid && sensor && conveyor && machine && customer && pairedSensor">
  <div class="mx-3 mt-1 mb-5">
    <div class="bg-white card p-3 mt-2">
      <div class="d-flex align-items-center mb-2">
        <div>Paired Sensor Details:</div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          ID:
          <span class="grey">
            {{ pairedSensor.id ? pairedSensor.id : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Alias Name:
          <span class="grey">
            {{ pairedSensor.aliasName ? pairedSensor.aliasName : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Battery Level:
          <span class="grey">
            {{ pairedSensor.batteryLevel ? pairedSensor.batteryLevel : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Conveyor ID:
          <span class="grey">
            {{ pairedSensor.conveyorId ? pairedSensor.conveyorId : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Conveyor Name:
          <span class="grey">
            {{ pairedSensor.conveyorName ? pairedSensor.conveyorName : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Created UTC:
          <span class="grey">
            {{ pairedSensor.createdUtc ? formatDateTime(pairedSensor.createdUtc) : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Description:
          <span class="grey">
            {{ pairedSensor.description ? pairedSensor.description : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          EDID:
          <span class="grey">
            {{ pairedSensor.edid ? pairedSensor.edid : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Gateway ID:
          <span class="grey">
            {{ pairedSensor.gatewayId ? pairedSensor.gatewayId : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Gateway Name:
          <span class="grey">
            {{ pairedSensor.gatewayName ? pairedSensor.gatewayName : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Is Active:
          <span class="grey">
            {{ pairedSensor.isActive ? pairedSensor.isActive : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Mac Address:
          <span class="grey">
            {{ pairedSensor.macAddress ? pairedSensor.macAddress : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Machine ID:
          <span class="grey">
            {{ pairedSensor.machineId ? pairedSensor.machineId : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Machine Name:
          <span class="grey">
            {{ pairedSensor.machineName ? pairedSensor.machineName : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Role:
          <span class="grey">
            {{ pairedSensor.role ? pairedSensor.role : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          SID:
          <span class="grey">
            {{ pairedSensor.sid ? pairedSensor.sid : "N/A" }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
