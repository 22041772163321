<div class="mobile" *ngIf="belt">
  <app-mobile-header [header]="'Scanning the RFID Chip'" [routerLinkUrl]="['/fitting']">
  </app-mobile-header>

  <div class="mx-3">
    <div class="bg-white card p-3 mt-3" *ngIf="!belt.rfidTagId">
      <div class="text-center">Connect the RFID reader to your phone and scan the belt chip</div>

      <div class="small-grey text-center mt-4 px-5">
        Waiting for the reader to pick up the RFID signal
      </div>

      <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-primary mt-2 me-2" (click)="startScanning()">
          Start Scanning
        </button>
        <button type="button" class="btn btn-primary mt-2 ms-2" (click)="stopScanning()">
          Stop Scanning
        </button>
      </div>
    </div>

    <div class="bg-white card p-3 mt-2" *ngIf="belt.rfidTagId">
      <div class="text-center">RFID Tag ID:</div>
      <div class="text-center">{{ belt.rfidTagId }}</div>
      <button (click)="navToHome()" type="button" class="btn btn-primary mt-4">Save</button>
    </div>
  </div>
</div>
