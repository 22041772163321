import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';
import { CBeltAuthGuard } from './guards/c-belt-auth.guard';

import { CBeltComponent } from './components/c-belt/c-belt.component';
import { LanguageComponent } from './pages/language/language.component';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { TranslationsComponent } from './pages/translations/translations.component';
import { BeltEditComponent } from './components/belts/belt-edit/belt-edit.component';
import { BeltListComponent } from './components/belts/belt-list/belt-list.component';
import { BeltImportComponent } from './components/belts/belt-import/belt-import.component';
import { BeltCreateComponent } from './components/belts/belt-create/belt-create.component';
import { ScanSensorComponent } from './components/c-belt/scan-sensor/scan-sensor.component';
import { SurveyListComponent } from './components/surveys/survey-list/survey-list.component';
import { ScanningComponent } from './components/c-belt/scanning/scanning/scanning.component';
import { AddCustomerComponent } from './components/surveys/add-customer/add-customer.component';
import { CbeltListComponent } from './components/c-belt/installing/cbelt-list/cbelt-list.component';
import { AddSensorsComponent } from './components/c-belt/installing/add-sensors/add-sensors.component';
import { EditSensorComponent } from './components/c-belt/installing/edit-sensor/edit-sensor.component';
import { ScanningRfidComponent } from './components/c-belt/fitting/scanning-rfid/scanning-rfid.component';
import { SystemSetupComponent } from './components/c-belt/installing/system-setup/system-setup.component';
import { InspectionListComponent } from './components/inspections/inspection-list/inspection-list.component';
import { ConfigureWifiComponent } from './components/c-belt/installing/configure-wifi/configure-wifi.component';
import { InstallingHomeComponent } from './components/c-belt/installing/installing-home/installing-home.component';
import { NewGatewayDeviceComponent } from './components/c-belt/installing/new-gateway-device/new-gateway-device.component';
import { ConfiguringDeviceComponent } from './components/c-belt/installing/configuring-device/configuring-device.component';
import { CBeltConfigurationComponent } from './components/c-belt/fitting/c-belt-configuration/c-belt-configuration.component';
import { CBeltInstallationComponent } from './components/c-belt/installing/c-belt-installation/c-belt-installation.component';
import { AddSensorManuallyComponent } from './components/c-belt/installing/add-sensor-manually/add-sensor-manually.component';
import { PairConveyorReaderComponent } from './components/c-belt/installing/pair-conveyor-reader/pair-conveyor-reader.component';
import { SelectConfigurationComponent } from './components/c-belt/modifying/select-configuration/select-configuration.component';
import { SystemSetupSensorsComponent } from './components/c-belt/installing/system-setup-sensors/system-setup-sensors.component';
import { SensorConfigurationComponent } from './components/c-belt/installing/sensor-configuration/sensor-configuration.component';
import { GatewayConfigurationComponent } from './components/c-belt/installing/gateway-configuration/gateway-configuration.component';
import { SystemSetupPairRfidComponent } from './components/c-belt/installing/system-setup-pair-rfid/system-setup-pair-rfid.component';
import { PairConveyorAndBeltComponent } from './components/c-belt/installing/pair-conveyor-and-belt/pair-conveyor-and-belt.component';
import { ConfigurationOverviewComponent } from './components/c-belt/installing/configuration-overview/configuration-overview.component';
import { ConfiguringDeviceFourGComponent } from './components/c-belt/installing/configuring-device-four-g/configuring-device-four-g.component';
import { CalibrateConveyorReaderComponent } from './components/c-belt/installing/calibrate-conveyor-reader/calibrate-conveyor-reader.component';
import { PairConveyorReaderManuallyComponent } from './components/c-belt/installing/pair-conveyor-reader-manually/pair-conveyor-reader-manually.component';
import { ConveyorReaderConfigurationComponent } from './components/c-belt/installing/conveyor-reader-configuration/conveyor-reader-configuration.component';
import { SelectConfigurationPairRfidComponent } from './components/c-belt/modifying/select-configuration-pair-rfid/select-configuration-pair-rfid.component';
import { AddRfidManuallyComponent } from './components/c-belt/installing/add-rfid-manually/add-rfid-manually.component';
import { LogoutComponent } from './pages/logout/logout.component';

const isMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor;
  return /android/i.test(userAgent);
};

const guard = isMobileOperatingSystem() ? CBeltAuthGuard : MsalGuard;

const routes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: '',
    canActivate: [guard],
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'surveys',
      },
      {
        path: 'surveys',
        canActivate: [guard],
        component: SurveyListComponent,
      },
      {
        path: 'surveys/:survey/import',
        canActivate: [guard],
        component: BeltImportComponent,
      },
      {
        path: 'surveys/add-customer',
        canActivate: [guard],
        component: AddCustomerComponent,
      },
      {
        path: 'surveys/:survey',
        canActivate: [guard],
        component: BeltListComponent,
      },
      {
        path: 'surveys/:survey/belts/create',
        canActivate: [guard],
        component: BeltCreateComponent,
      },
      {
        path: 'surveys/:survey/belts/:belt/edit',
        canActivate: [guard],
        component: BeltEditComponent,
      },
      {
        path: 'inspections',
        canActivate: [guard],
        component: InspectionListComponent,
      },
      {
        path: 'c-belt',
        canActivate: [guard],
        component: CBeltComponent,
      },
      {
        path: 'scanning',
        canActivate: [guard],
        component: ScanningComponent,
      },
      {
        path: 'scan-sensor',
        canActivate: [guard],
        component: ScanSensorComponent,
      },
      {
        path: 'fitting',
        canActivate: [guard],
        component: CBeltConfigurationComponent,
      },
      {
        path: 'fitting/scanning/:orderNumber',
        canActivate: [guard],
        component: ScanningRfidComponent,
      },
      {
        path: 'fitting/scanning/:orderNumber/add-manually',
        canActivate: [guard],
        component: AddRfidManuallyComponent,
      },
      {
        path: 'installing',
        canActivate: [guard],
        component: InstallingHomeComponent,
      },
      {
        path: 'select-config',
        canActivate: [guard],
        component: SelectConfigurationComponent,
      },
      {
        path: 'select-config-pair-rfid',
        canActivate: [guard],
        component: SelectConfigurationPairRfidComponent,
      },
      {
        path: 'installing/:company_id',
        canActivate: [guard],
        component: CBeltInstallationComponent,
      },
      {
        path: 'installing/:company_id/configuration-overview',
        canActivate: [guard],
        component: ConfigurationOverviewComponent,
      },
      {
        path: 'installing/:company_id/gateway-configuration',
        canActivate: [guard],
        component: GatewayConfigurationComponent,
      },
      {
        path: 'installing/:company_id/gateway-configuration/new-gateway-device',
        canActivate: [guard],
        component: NewGatewayDeviceComponent,
      },
      {
        path: 'installing/:company_id/gateway-configuration/new-gateway-device/configure-wifi/configuring-device',
        canActivate: [guard],
        component: ConfiguringDeviceComponent,
      },
      {
        path: 'installing/:company_id/gateway-configuration/new-gateway-device/configure-wifi/:gatewayDeviceId',
        canActivate: [guard],
        component: ConfigureWifiComponent,
      },
      {
        path: 'installing/:company_id/gateway-configuration/new-gateway-device/configure-wifi/configuring-device-four-g',
        component: ConfiguringDeviceFourGComponent,
        canActivate: [guard],
      },
      {
        path: 'installing/:company_id/system-setup',
        canActivate: [guard],
        component: SystemSetupComponent,
      },
      {
        path: 'installing/:company_id/conveyor-reader-configuration',
        canActivate: [guard],
        component: ConveyorReaderConfigurationComponent,
      },
      {
        path: 'installing/:company_id/conveyor-reader-configuration/pair-conveyor-reader/:conveyorName',
        canActivate: [guard],
        component: PairConveyorReaderComponent,
      },
      {
        path: 'installing/:company_id/conveyor-reader-configuration/pair-belt-and-conveyor/:conveyorName',
        canActivate: [guard],
        component: PairConveyorAndBeltComponent,
      },
      {
        path: 'installing/:company_id/conveyor-reader-configuration/pair-conveyor-reader-manually/:conveyorName',
        canActivate: [guard],
        component: PairConveyorReaderManuallyComponent,
      },
      {
        path: 'installing/:company_id/calibrate-conveyor',
        canActivate: [guard],
        component: CalibrateConveyorReaderComponent,
      },
      {
        path: 'installing/:company_id/system-setup-sensors',
        canActivate: [guard],
        component: SystemSetupSensorsComponent,
      },
      {
        path: 'installing/:company_id/system-setup-pair-rfid',
        canActivate: [guard],
        component: SystemSetupPairRfidComponent,
      },
      {
        path: 'installing/:company_id/system-setup-pair-rfid/select-belt',
        canActivate: [guard],
        component: CbeltListComponent,
      },
      {
        path: 'installing/:company_id/sensor-configuration',
        canActivate: [guard],
        component: SensorConfigurationComponent,
      },
      {
        path: 'installing/:company_id/sensor-configuration/add-edit-sensors',
        canActivate: [guard],
        component: AddSensorsComponent,
      },
      {
        path: 'installing/:company_id/sensor-configuration/add-edit-sensors/add-sensor-manually',
        canActivate: [guard],
        component: AddSensorManuallyComponent,
      },
      {
        path: 'installing/:company_id/sensor-configuration/edit-sensor',
        canActivate: [guard],
        component: EditSensorComponent,
      },
      {
        path: 'translations',
        canActivate: [MsalGuard],
        component: TranslationsComponent,
      },
      {
        path: 'languages',
        component: LanguageComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
