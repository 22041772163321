import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../../app/services/auth/auth.service';
import { ModalService } from '../../../app/services/modal/modal.service';
import { StateService } from '../../../app/services/c-belt/state.service';
import { AlertService } from '../../../app/shared/services/alert/alert.service';
import { MsalCBeltService, MsalScope } from '../../../app/services/c-belt/msal.service';

import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';
import * as InstallationActions from '../../../app/state/installation/installation.actions';

@Component({
  selector: 'app-c-belt',
  templateUrl: './c-belt.component.html',
  styleUrls: ['./c-belt.component.scss'],
})
export class CBeltComponent implements OnInit {
  settingUser: boolean = false;
  installationInProgress: boolean = false;

  current: ConfigurationState = null;

  constructor(
    private store: Store,
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
    private modalService: ModalService,
    private stateService: StateService,
    private msalCBeltService: MsalCBeltService
  ) {}

  async ngOnInit(): Promise<void> {
    this.settingUser = true;
    if (this.isMobileOperatingSystem()) {
      await this.msalCBeltService.msalInit(MsalScope.cBelt);
      await this.msalCBeltService.cBeltLogin();
      this.subscribeToCurrentConfiguration();
    }
    this.settingUser = false;
  }

  subscribeToCurrentConfiguration() {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store.select(select).subscribe((current) => {
      this.current = cloneDeep(current);
      this.installationInProgress = !this.isCurrentEmpty(this.current);
    });
  }

  private isCurrentEmpty(current: ConfigurationState): boolean {
    return Object.keys(current).every((key) => {
      if (Array.isArray(current[key])) {
        return current[key].length === 0;
      } else {
        return !current[key];
      }
    });
  }

  navTo(route: string) {
    if (!this.authService.hasInstallationPermission()) {
      this.alertService.alertError('Not enough permissions.');
      return;
    }

    if (route == 'select-config') {
      this.toggleEditingStatus(true);
    } else {
      this.toggleEditingStatus(false);
    }
    this.router.navigate([route]);
  }

  private isMobileOperatingSystem(): boolean {
    var userAgent = navigator.userAgent || navigator.vendor;
    return /android/i.test(userAgent);
  }

  resetState() {
    this.modalService.showConfirmModal({
      title: 'Reset State',
      message: 'Would you like to start a new configuration?',
      confirm: () => {
        this.stateService.initState();
        this.alertService.alertSuccess('Local State has been reset.');
      },
    });
  }

  toggleEditingStatus(editing: boolean) {
    this.store.dispatch(InstallationActions.updateEditingStatus({ editing }));
  }
}
