import { cloneDeep } from 'lodash';
import { NFC } from '@awesome-cordova-plugins/nfc/ngx';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { ConveyorReader } from 'src/app/models/conveyorReader.model';

import { AlertService } from 'src/app/shared/services/alert/alert.service';

import { Store } from '@ngrx/store';
import { Subscription, take } from 'rxjs';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-pair-conveyor-reader',
  templateUrl: './pair-conveyor-reader.component.html',
  styleUrls: ['./pair-conveyor-reader.component.scss'],
})
export class PairConveyorReaderComponent implements OnInit, OnDestroy {
  tagId: any;
  editing: boolean;
  enabled: boolean;
  baseRoute: string;
  companyId: string;
  current: ConfigurationState = null;
  conveyorReader: ConveyorReader = new ConveyorReader();

  private scannerSub: Subscription;

  constructor(
    private nfc: NFC,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.route.url.subscribe((route) => {
      this.baseRoute = route[0].path;
    });
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.conveyorReader.conveyorName = params['conveyorName'];
    });
    this.addListener();
    this.subscribeToCurrentConfiguration();
  }

  async ngOnDestroy(): Promise<void> {
    if (this.scannerSub) {
      this.scannerSub.unsubscribe();
    }
  }

  subscribeToCurrentConfiguration() {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.editing = this.current.editing;
        if (this.editing) {
          this.tagId = this.current.conveyorReader.readerId;
        }
      });
  }

  async addListener() {
    try {
      this.alertService.alertInfo('Attempting to Scan');
      this.scannerSub = this.nfc.addNdefListener().subscribe({
        next: (tag) => {
          let payload = tag['tag']['ndefMessage'][0]['payload'];
          let stringValue = this.nfc.bytesToString(payload);
          let hexValue = stringValue.slice(3, 20);
          this.tagId = this.macAddressToDecimal(hexValue);
        },
        error: (err) => {
          if (err == 'NFC_DISABLED') {
            this.enabled = false;
            this.alertService.alertError('Please Enable NFC');
          }
        },
        complete: () => {
          console.log('Cameron: Complete');
        },
      });
    } catch (err) {
      this.alertService.alertError('Error scanning NFC tag');
    }
  }

  navToPairReaderManually() {
    this.router.navigate([
      this.baseRoute,
      this.companyId,
      'conveyor-reader-configuration',
      'pair-conveyor-reader-manually',
      this.conveyorReader.conveyorName,
    ]);
  }

  continue() {
    if (this.tagId) {
      this.updateSensorId();
      this.navToPairReaderManually();
    }
  }

  updateSensorId() {
    this.store.dispatch(
      InstallationActions.updateNewSensorId({ sensorId: this.tagId })
    );
  }

  macAddressToDecimal(macAddress: string): number {
    const macParts = macAddress.split(':');
    let decimalNumber = 0;

    for (let i = 0; i < macParts.length; i++) {
      const hexPart = parseInt(macParts[i], 16);
      decimalNumber = decimalNumber * 256 + hexPart;
    }

    return decimalNumber;
  }
}
