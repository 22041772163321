import { Image } from '../shared/components/image-input/models/image';
import { Model } from '../state/state-utils';

export class Belt implements Model {
  // DB
  id: string;
  created: Date = new Date();
  updated: Date = new Date();
  createdBy: string;
  updatedBy: string;

  // Shared required
  wtl: string;
  synOrMod: string = null;
  productionLine: string;
  productReference: string;
  length: number;
  width: number;
  quantity: number = 1;
  surveyId: string;
  machineId: string;

  // Optional Shared
  carriersBordoflex: string;
  comments: string;
  attachmentUrl: string;
  surveyor?: any;
  image?: Image;
  deleted: boolean;
  beltAccess: string;
  itemReference: string;

  // SYNTHETIC BELTS
  weldType: string = null;
  weldDirection: string = null;
  joint: JointType = null;
  material: string;
  trackingGuide: string;

  // MODULAR BELTS
  sprocketBore: string;
  sprocketPd: string;
  pinMaterial: string;
  beltColour: string;
  beltMaterial: string;
  beltType: string;

  /**
   * @description Dirty flag should be `true` if the value has been edited on the client device.
   */
  dirty: boolean = true;

  constructor(surveyId: string) {
    this.surveyId = surveyId;
  }
}

export enum JointType {
  OPEN = 'Open Piece',
  PREPARED = 'Prepared Ends',
  ENDLESS = 'Endless',
  LACE = 'Lace Fastener',
  HOOK = 'Hook Fastener',
  RS62 = 'Staple (RS62)',
  RS125 = 'Staple (RS125)',
  SPIRAL = 'Spiral Lace',
  ZIPLOCK = 'Ziplock',
}
