import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { RoleEnum } from './../../utils/util.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';

import { AuthRole, UserAzureDetails } from 'src/app/models/userAzureDetails.model';

import { Store } from '@ngrx/store';
import { loginSuccess } from './../../state/user/user.actions';
import { AmmscanApiService } from '../api/ammscanApi.service';

export interface User {
  id: string;
  email: string;
  token: string;
  role: RoleEnum;
  organisationRoles: { [key: string]: AuthRole[] };
}

@Injectable()
export class AuthService {
  private user: User = null;

  constructor(
    private ammscanApiService: AmmscanApiService,
    private store: Store,
    private http: HttpClient,
    private alertService: AlertService
  ) {}

  public getUser(): User {
    return this.user;
  }

  private setUserFromData(userData) {
    this.user = {
      id: userData.id,
      email: userData.email,
      token: userData.token,
      role: RoleEnum.ADMIN,
      organisationRoles: {},
    };
  }

  public async onMsalAuthChange(
    userData: {
      id: string;
      email: string;
      token: string;
    },
    options?: {
      fetchOrgPermissions: boolean;
    }
  ): Promise<void> {
    if (!userData) {
      this.user = null;
      return;
    }

    if (this.user?.token != userData.token || !this.user.role) {

      if (!!Object.keys(this.user?.organisationRoles ?? {}).length) {
        options = { fetchOrgPermissions: true };
      }

      this.setUserFromData(userData);

      try {
        await this.fetchAndAssignUserRole(userData.id);
        if (options?.fetchOrgPermissions) {
          await this.fetchAndAssignUserOrgPermissions();
        }
        this.store.dispatch(loginSuccess({ user: this.user }));
      } catch (err) {
        console.log(err);
      }
    }
  }

  async fetchAndAssignUserRole(userId: string) {
    try {
      // const baseUrl: string = environment.baseUrl;
      this.user.role = await this.ammscanApiService.get(`/users/${userId}/role`);
    } catch (err) {
      this.alertService.handleAmmscanError(err);
    }
  }

  async fetchAndAssignUserOrgPermissions() {
    try {
      const details = await this.fetchUserAzureDetails();
      if (!details) return;

      this.assignUserOrgPermissions(details);
    } catch (err) {
      this.alertService.handleCBeltError(err);
    }
  }

  private async fetchUserAzureDetails(): Promise<UserAzureDetails> {
    return new Promise((resolve, reject) => {
      const code = environment.code;
      const baseUrl = environment.cBeltApi;
      const params = { principalId: this.user.id, code };

      this.http.get(baseUrl + '/users', { params: params }).subscribe({
        next: (response) => {
          if (!response['result']) {
            resolve(null);
          } else {
            resolve(response['result']);
          }
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  private assignUserOrgPermissions(details: UserAzureDetails) {
    for (const role of details.authRoles) {
      if (!this.user.organisationRoles[role.orgId]) this.user.organisationRoles[role.orgId] = [];

      if (this.user.organisationRoles[role.orgId].includes(role.roleName as AuthRole)) continue;
      this.user.organisationRoles[role.orgId].push(role.roleName as AuthRole);
    }
  }

  public hasInstallationPermission(): boolean {
    const organisationIds = Object.keys(this.user?.organisationRoles);
    for (const orgId of organisationIds) {
      const orgRoles = this.user.organisationRoles[orgId];
      if (orgRoles?.includes(AuthRole.SysAdmin) || orgRoles.includes(AuthRole.SalesUser)) {
        return true;
      }
    }
    return false;
  }

  // public hasInstallationPermission(orgId: string): boolean {
  //   const orgRoles = this.user?.organisationRoles[orgId];
  //   return orgRoles?.includes(AuthRole.SysAdmin) || orgRoles.includes(AuthRole.SalesUser);
  // }
}
