import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription, take } from 'rxjs';
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { v4 as uuidv4 } from 'uuid';

import { SortColumn, SortableHeaderDirective } from '../../../shared/directives/sortable.directive';
import { Table } from '../../../shared/components/table/table.component';

import { Customer } from '../../../models/customer.model';
import { Survey } from '../../../models/survey.model';

import { AlertService } from '../../../shared/services/alert/alert.service';
import { AuthService } from '../../../services/auth/auth.service';
import {
  Breadcrumb,
  BreadcrumbService,
} from '../../../shared/services/breadcrumb/breadcrumb.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { MappingService } from '../../../services/mapping/mapping.service';
import { ModalService } from '../../../services/modal/modal.service';
import { MsalCBeltService, MsalScope } from 'src/app/services/c-belt/msal.service';
import { SyncService } from '../../../services/sync/sync.service';
import { TranslateService } from './../../../services/translate.service';
import { UtilService } from '../../../utils/util.service';

import * as CustomerActions from '../../../state/customer/customer.actions';
import * as CustomerSelectors from '../../../state/customer/customer.selector';
import * as SurveySelectors from '../../../state/survey/survey.selector';
import * as SurveyActions from '../../../state/survey/survey.actions';
import * as UserSelectors from '../../../state/user/user.selector';
import { loginSuccess } from 'src/app/state/user';

@Component({
  selector: 'app-survey-list',
  templateUrl: 'survey-list.component.html',
  styleUrls: ['survey-list.component.scss'],
})
export class SurveyListComponent implements OnInit, OnDestroy {
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  @ViewChild('filterModal') filterModal;

  private surveys$ = this.store.select(SurveySelectors.selectAllSurveys);
  private customers$ = this.store.select(CustomerSelectors.selectAllCustomers);
  public currentUser$ = this.store.select(UserSelectors.selectCurrentUser);

  public surveyTable: Table;
  private surveys: Survey[] = [];
  public filteredSurveys: Survey[] = [];
  private subscriptions: Subscription[] = [];

  public owners = [];
  public surveyCustomerIds: string[] = [];
  private allCustomers: Customer[] = [];
  public allCustomerKeyValues: { key: string; value: string }[] = [];
  public currentCustomerId: string;

  private appliedFilters: SurveyFilter = new SurveyFilter();
  private selectedFilters: SurveyFilter = new SurveyFilter();

  public searchText: string = '';

  public maxDate = new Date();
  private bsConfig?: Partial<BsDatepickerConfig>;

  constructor(
    private bsModalRef: BsModalRef,
    private alertService: AlertService,
    private authService: AuthService,
    private syncService: SyncService,
    private breadcrumbService: BreadcrumbService,
    private map: MappingService,
    private loadingService: LoadingService,
    private modalService: ModalService,
    private msalCBeltService: MsalCBeltService,
    private router: Router,
    private utils: UtilService,
    private store: Store,
    public t: TranslateService,
    private actions: Actions
  ) {
    this.bsConfig = Object.assign(
      {},
      {
        adaptivePosition: true,
        isAnimated: true,
        rangeInputFormat: 'DD/MM/YYYY',
        dateInputFormat: 'DD/MM/YYYY',
        showWeekNumbers: false,
        containerClass: 'theme-default',
        showPreviousMonth: true,
      }
    );
  }

  async ngOnInit(): Promise<void> {
    if (this.isMobileOperatingSystem()) {
      // TODO: @Cam show page block loader
      await this.msalCBeltService.msalInit(MsalScope.surveys);
      await this.msalCBeltService.cBeltLogin();
    }

    const user = this.authService.getUser();
    if (user) {
      this.init();
    } else {
      this.actions.pipe(ofType(loginSuccess), take(1)).subscribe(() => {
        this.init();
      });
    }
  }

  private init() {
    this.loadingService.show();
    this.store.dispatch(CustomerActions.fetchCustomers());
    this.store.dispatch(SurveyActions.fetchSurveys());

    this.initBreadcrumbs();

    this.subscribeToEnrichedSurveys();
    this.subscribeToCustomers();

    this.store.select(SurveyActions.fetchSurveysSuccess).subscribe(() => {
      this.loadingService.hide();
    });
  }

  private isMobileOperatingSystem(): boolean {
    var userAgent = navigator.userAgent || navigator.vendor;
    return /android/i.test(userAgent);
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      if (sub) sub.unsubscribe();
    });
  }

  private initBreadcrumbs() {
    this.breadcrumbService.setBreadcrumbs([]);
    const crumb = new Breadcrumb('Surveys', ['/', 'surveys']);
    this.breadcrumbService.pushBreadcrumb(crumb);
  }

  private subscribeToEnrichedSurveys() {
    const enrichedSurveySub = this.surveys$.subscribe((surveys) => {
      if (!surveys?.length) return;

      this.surveys = Object.assign([], surveys);
      this.filterAndMapSurveys();

      let surveyOwners = this.surveys.map((survey) => {
        return survey.user ? survey.user.email : null;
      });
      surveyOwners = surveyOwners
        .filter((owner) => owner)
        .sort((a, b) => a.localeCompare(b, 'en', { numeric: true }));

      this.owners = [...new Set(surveyOwners)];

      const surveyCustomers = this.surveys.map((survey) => ({
        name: survey.customer?.customer_name,
        value: survey.customer?.id,
      }));

      const uniqueSurveyCustomers = this.utils.getUniqueObjects(surveyCustomers);
      this.surveyCustomerIds = this.utils.sortAlphaNumeric(
        uniqueSurveyCustomers,
        'name' as SortColumn
      );
    });
    this.subscriptions.push(enrichedSurveySub);
  }

  private subscribeToCustomers() {
    const customersSub = this.customers$.subscribe((customers) => {
      if (!customers) return;
      this.allCustomers = customers;

      if (!this.allCustomerKeyValues.length) {
        this.mapCustomersKeyValueDropdown(customers);
      }

      this.allCustomerKeyValues.sort((a, b) => this.utils.compare(a.key, b.key, true));
    });
    this.subscriptions.push(customersSub);
  }

  private mapCustomersKeyValueDropdown(customers: Customer[]): void {
    const filteredCustomers = customers.filter((customer) => customer.customer_name !== null);
    this.allCustomerKeyValues = filteredCustomers
      .map((customer) => ({
        key: customer.customer_name ?? 'No name - ' + customer.id,
        value: customer.id,
      }))
      .sort((a, b) => {
        const aLowercaseName = a.key.toLowerCase();
        const bLowercaseName = b.key.toLowerCase();
        return aLowercaseName.localeCompare(bLowercaseName, 'en', { numeric: true });
      });
  }

  public hasActiveFilter() {
    const hasOwner = this.appliedFilters.owners.length;
    const hasSite = this.appliedFilters.surveyCustomerIds.length;
    const hasDates = this.appliedFilters.dates.length;
    return hasOwner || hasSite || hasDates;
  }

  public getFilterCount(): number {
    let count: number = 0;
    count += this.appliedFilters.owners.length;
    count += this.appliedFilters.surveyCustomerIds.length;
    count += this.appliedFilters.dates.length ? 1 : 0;
    return count;
  }

  public applyFilters() {
    this.hideModal();
    this.appliedFilters = Object.assign({}, this.selectedFilters);
    this.filterAndMapSurveys();
  }

  public clearFilters() {
    this.hideModal();
    this.appliedFilters = new SurveyFilter();
    this.selectedFilters = new SurveyFilter();
    this.filterAndMapSurveys();
  }

  public navigateToEditSurvey(survey: Survey) {
    this.router.navigate(['/surveys', survey.id]);
  }

  public async onRemoveSurvey(survey: Survey) {
    this.modalService.showDeleteModal({
      title: `${this.t.translate('_REMOVE_SURVEY')}: ${survey.customer.customer_name}`,
      message: `${this.t.translate('_ARE_YOU_SURE?')}`,
      delete: () => this.removeSurvey(survey),
    });
  }

  private async removeSurvey(survey: Survey) {
    survey.updated = new Date();
    survey.deleted = true;
    survey.dirty = true;

    this.store.dispatch(SurveyActions.removeSurveySuccess({ survey }));
    this.alertService.alertSuccess(this.t.translate('_SURVEY_REMOVED_SUCCESSFULLY'));
    await this.syncService.trySync();
  }

  public async createSurvey() {
    if (!this.currentCustomerId) {
      this.alertService.alertWarning('Select a customer');
      return;
    }

    const survey = new Survey();
    survey.customerId = this.currentCustomerId;
    survey.customer = this.allCustomers.find((customer) => customer.id == survey.customerId);
    survey.user = { email: this.authService.getUser().email };
    survey.id = uuidv4();

    this.store.dispatch(SurveyActions.addSurveySuccess({ survey }));
    await this.syncService.trySync();
    this.hideModal();
    this.currentCustomerId = null;

    this.router.navigate(['/', 'surveys', survey.id]);
  }

  public showFilterModal() {
    this.selectedFilters = Object.assign({}, this.appliedFilters);
    this.showModal(this.filterModal, { class: 'modal-md' });
  }

  private showModal(modal, options) {
    this.bsModalRef = this.modalService.show(modal, options);
  }

  public hideModal() {
    this.bsModalRef.hide();
  }

  public onNewSurveyClick(surveyModal) {
    const customers = this.allCustomers;
    this.mapCustomersKeyValueDropdown(customers);
    this.showModal(surveyModal, { class: 'modal-md' });
  }

  public onAddCustomerClick() {
    this.hideModal();
    this.router.navigate(['/surveys/add-customer']);
  }

  private doesSurveyMatchSearch(survey: Survey): boolean {
    const search = this.searchText.toLowerCase();
    const totalString =
      survey.id +
      ' ' +
      survey.user?.email +
      ' ' +
      survey.customer?.customer_no +
      ' ' +
      survey.customer?.customer_name +
      ' ';
    return totalString.toLowerCase().includes(search);
  }

  public filterAndMapSurveys() {
    let filteredSurveys = [...this.surveys];

    filteredSurveys.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());

    if (this.appliedFilters.owners.length) {
      filteredSurveys = filteredSurveys.filter((survey) => {
        return this.appliedFilters.owners.find((owner) => survey.user?.email == owner);
      });
    }

    if (this.appliedFilters.surveyCustomerIds.length) {
      filteredSurveys = filteredSurveys.filter((survey) => {
        return this.appliedFilters.surveyCustomerIds.find(
          (surveyCustomer) => survey.customerId == surveyCustomer
        );
      });
    }

    if (this.appliedFilters.dates.length) {
      filteredSurveys = filteredSurveys.filter((survey) => {
        const start = this.utils.getDateAtMidnight(this.appliedFilters.dates[0]);
        const end = this.utils.getDateAtMidnight(
          this.appliedFilters.dates[this.appliedFilters.dates.length - 1]
        );
        end.setDate(end.getDate() + 1);

        const surveyDate = new Date(survey.created).getTime();

        return surveyDate >= start.getTime() && surveyDate < end.getTime();
      });
    }

    this.filteredSurveys = filteredSurveys.filter((x) => this.doesSurveyMatchSearch(x));
    this.surveyTable = this.map.surveysToTable(this.filteredSurveys, (survey) =>
      this.onRemoveSurvey(survey)
    );
  }

  public clearSelectedFilterDates() {
    this.selectedFilters.dates = [];
  }
}

export class SurveyFilter {
  owners: any[];
  surveyCustomerIds: any[];
  dates: any[];

  constructor() {
    this.owners = [];
    this.surveyCustomerIds = [];
    this.dates = [];
  }
}
