import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthRole } from './../../models/userAzureDetails.model';
import { Language } from './../../models/language';
import { Translation } from './../../models/translation';

import { AuthService, User } from './../../services/auth/auth.service';
import { AlertService } from './../../shared/services/alert/alert.service';
import { LoadingService } from './../../shared/services/loading/loading.service';
import { TranslateContext, TranslateService } from '../../services/translate.service';
import { RoleEnum } from 'src/app/utils/util.service';

import * as keys from '../../../../_files/keys.json';

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss'],
})
export class TranslationsComponent implements OnInit, OnDestroy {
  currentUser: User;
  language: Language;
  languages: Language[] = [];
  translations: Translation[] = [];

  sortField: string = 'key';
  sortDirection: string = 'ASC';

  loading: boolean = false;

  private keys: string[] = (keys as any).default.sort();
  private sub: Subscription;

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private loadingService: LoadingService,
    public t: TranslateService
  ) {}

  public ngOnInit() {
    this.subscribeToLanguages();
  }

  public isAdmin(): boolean {
    const user = this.authService.getUser();
    return user.role === RoleEnum.ADMIN;
  }

  public ngOnDestroy() {
    this.t.unsubscribeFromLanguageChanges(this.sub);
  }

  async fetchCscLanguage() {
    const language = await this.t.fetchCscLanguageByUser();

    if (!language) {
      throw new Error('Language not found');
    }

    return language.id;
  }

  private async subscribeToLanguages() {
    this.loadingService.show();
    this.sub = this.t.subscribeToLanguageChanges(async (languages) => {
      const user = await this.authService.getUser();

      if (this.isAdmin()) {
        this.languages = languages;
      } else if (this.isAdmin()) {
        const userCscLanguageId = await this.fetchCscLanguage();
        this.languages = languages.filter((language) => {
          return language.id == userCscLanguageId;
        });
      }

      if (this.languages.length) {
        this.updateLanguage(this.languages[0]);
      }
    });
    this.loadingService.hide();
  }

  onLanguageChanged(event) {
    const language = this.languages.find((x) => x.id == event.target.value);
    if (language) {
      this.updateLanguage(language);
    }
  }

  private async updateLanguage(language: Language) {
    this.loadingService.show();
    try {
      this.language = language;
      let translations = await this.t.fetchLanguageTranslations(this.language);

      const allTranslations = this.keys.map((x) => {
        const translation = translations.find((y) => y.key === x) || new Translation();
        if (!translation.id) {
          translation.key = x;
          translation.language_id = language.id;
          translation.context = TranslateContext.common;
        }
        return translation;
      });

      translations = allTranslations.filter((translation) => !translation.key.startsWith('_"'));
      translations = translations.filter((translation) => translation.key.startsWith('_'));

      this.translations = translations;
    } catch (err) {
      this.alertService.handleHttpError(err);
    }
    this.loadingService.hide();
  }

  async onSave() {
    this.loadingService.show();
    try {
      await this.t.saveTranslations(this.translations);
      await this.updateLanguage(this.language);
      this.alertService.alertSuccess(this.t.translate('_SAVE_SUCCESS'));
    } catch (err) {
      this.alertService.handleHttpError(err);
    }
    this.loadingService.hide();
  }
}
