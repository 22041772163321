import { ModalService } from './../../services/modal/modal.service';
import { Platform } from '@angular/cdk/platform';
import { Component, afterNextRender } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take, timer } from 'rxjs';

@Component({
  selector: 'app-install-button',
  templateUrl: './install-button.component.html',
  styleUrls: ['./install-button.component.scss'],
})
export class InstallButtonComponent {
  isInstallable: boolean;
  isInstalled: boolean;

  private promptEvent: any;
  private bsModalRef: BsModalRef;

  constructor(
    private modalService: ModalService,
    private platform: Platform
  ) {
    // Only load on client side:
    afterNextRender(() => {
      if (!('serviceWorker' in navigator)) {
        this.isInstallable = false;
      }
  
      this.isInstalled = this.checkIsAppInstalled();
  
      if (!this.isInstalled) {
        this.initPwaPrompt();
      }
    });
  }

  private checkIsAppInstalled(): boolean {
    window.addEventListener('appinstalled', () => {
      // this.isInstalled = true;
    });

    return window.matchMedia('(display-mode: standalone)').matches ||
      window.matchMedia('(display-mode: fullscreen)').matches ||
      window.matchMedia('(display-mode: minimal-ui)').matches
  }

  private initPwaPrompt() {
    const isMac = navigator.userAgent.includes('Macintosh;');
    const isWindows = navigator.userAgent.includes('Windows');
    const isDesktop = isMac || isWindows;
    
    if (isDesktop) {
      // do nothing for now, maybe later
    } else {
      if (this.platform.ANDROID) {
        window.addEventListener('beforeinstallprompt', (event: any) => {
          // hide default prompt for now
          event.preventDefault();
          this.promptEvent = event;
          this.openPromptComponent();
        });
      }

      // iOS cant be installed with prompt
      if (this.platform.IOS) {
        const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
        if (!isInStandaloneMode) {
          this.openPromptComponent();
        }
      }
    }
  }

  private openPromptComponent() {
    timer(3000)
      .pipe(take(1))
      .subscribe(() => {
        this.isInstallable = true;
      });
  }

  onInstallClick(dialog) {
    try {
      if (this.platform.IOS) {
        this.showModal(dialog)
      } else if (this.platform.ANDROID) {
        this.promptEvent.prompt();
      } else {
        console.log('Not installable');
      }
    } catch (err) {
      console.log(err);
    }
  }

  showModal(modal) {
    this.bsModalRef = this.modalService.show(modal);
  }

  hideModal() {
    this.bsModalRef.hide();
  }
}
