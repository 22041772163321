<div class="bg-white rounded border">
    <div class="d-flex align-items-center justify-content-between px-3 py-2 border-bottom">
        <h1 class="header my-2">{{t.translate("_SURVEYS")}}</h1>
        <button class="small btn btn-primary d-flex align-items-center" (click)="onAddSurveyClick()">
            <i class="material-symbols-outlined pe-2">add</i>
            {{t.translate("_ADD")}}
        </button>
    </div>

    <div class="p-2 border-bottom" *ngFor="let survey of surveys">
        <div class="d-flex justify-content-between align-items-center">
            <div class="sync-icon" [routerLink]="['/surveys', survey.id]">
                <i class="material-symbols-outlined text-muted d-block"
                    *ngIf="survey.dirty">cloud_off</i>
                <i class="material-symbols-outlined text-success d-block"
                    *ngIf="!survey.dirty">cloud_done</i>
            </div>
            
            <div class="survey-details ps-2" [routerLink]="['/surveys', survey.id]">
                <div class="fw-500 d-flex align-items-center overflow-ellipsis">
                    <span class="overflow-ellipsis">{{ (survey.customer?.customer_name | titlecase) || '-' }}</span>
                </div>
                <div class="small">
                    {{ survey.belts.length }} {{t.translate("_BELTS")}}
                </div>
            </div>

      <div class="d-flex flex-column align-items-end position-relative">
        <app-meta-menu [menuItems]="createMenuItems(survey)"></app-meta-menu>
        <div class="date text-nowrap">
          {{ survey.created | date }}
        </div>
      </div>
    </div>
  </div>

    <div class="p-3" *ngIf="!surveys?.length">{{t.translate("_NOTHING_FOUND")}}</div>
</div>
