import { TranslateService } from './../../../services/translate.service';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppFormGroup } from '../../../utils/app-form-group';
import { CustomValidators } from './../../../utils/custom-validators';

import { Belt, JointType } from '../../../models/belt.model';
import { Image } from './../../../shared/components/image-input/models/image';
import { Machine } from './../../../models/machine.model';
import { Survey } from './../../../models/survey.model';

import { AlertService } from './../../../shared/services/alert/alert.service';
import { CustomerService } from '../../../services/customer/customer.service';
import { FileService } from './../../../services/file/file.service';
import { ModalService } from './../../../services/modal/modal.service';
import { UtilService } from './../../../utils/util.service';

import * as BeltSelectors from '../../../state/belt/belt.selector';

@Component({
  selector: 'app-belt-input',
  templateUrl: './belt-input.component.html',
  styleUrls: ['./belt-input.component.scss'],
})
export class BeltInputComponent implements OnChanges {
  @Input() title: string;
  @Input() beltInput: Belt;
  @Input() survey: Survey;
  @Output() beltChange: EventEmitter<Belt> = new EventEmitter<Belt>();

  beltForm = new AppFormGroup({
    wtl: new FormControl('', [
      Validators.maxLength(10),
      CustomValidators.cannotBeWhitespace,
    ]),
    productionLine: new FormControl('', [Validators.maxLength(50)]),
    productReference: new FormControl('', [
      Validators.required,
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(100),
    ]),
    material: new FormControl('', [Validators.maxLength(250)]),
    length: new FormControl(null, [Validators.required, Validators.min(1)]), // Find actual minimums
    width: new FormControl(null, [Validators.required, Validators.min(1)]), // Find actual minimums
    quantity: new FormControl(1, [Validators.required, Validators.min(1)]),
    carriersBordoflex: new FormControl('', [
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(250),
    ]),
    beltAccess: new FormControl('', [
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(250),
    ]),
    trackingGuide: new FormControl('', [
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(250),
    ]),
    comments: new FormControl('', [
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(250),
    ]),
    sprocketBore: new FormControl('', [CustomValidators.cannotBeWhitespace]),
    sprocketPd: new FormControl('', [CustomValidators.cannotBeWhitespace]),
    pinMaterial: new FormControl('', [
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(250),
    ]),
    beltMaterial: new FormControl('', [
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(250),
    ]),
    beltColour: new FormControl('', [
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(250),
    ]),
    beltType: new FormControl('', [
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(250),
    ]),
    itemReference: new FormControl('', [
      CustomValidators.cannotBeWhitespace,
      Validators.maxLength(250),
    ]),
  });

  prodLineFormControl: AbstractControl = this.beltForm.get('productionLine');

  belt: Belt;
  syntheticBeltMaterials: any[] = [];
  modularBeltMaterials: any[] = [];

  public jointTypes = this.mapKeyValuesFromEnum(JointType);
  public weldTypes = [
    'Finger Splice',
    'Business Splice',
    'Finger-Over-Finger',
    'Step Splice',
    'Quick Splice',
    'Classic',
  ];
  public weldDirections = ['Straight', 'Diagonal'];
  public beltAccessTypes = [
    'Head height',
    '2m height',
    '3m height',
    '4m height',
    'Hot environment',
    'Cold environment',
    'Access via permanent gantry',
    'Difficult access - discuss with customer',
    'Belt fully enclosed behind casing',
    'Scissor lift / mobile platform available on site',
  ];
  public beltAccess: string[] = [];

  imagePreview: Image = new Image();
  existingImage: Image;
  ammcareItemOptionId: string;

  highlightErrors: boolean;
  importFormSubmitted: boolean;

  syntheticMaterials$: Observable<any>;
  modularMaterials$: Observable<any>;
  modularTypes$: Observable<any>;
  modularColours$: Observable<any>;

  private image: Image = new Image();

  private ammcareItem: Machine;
  private ammcareItems: Machine[] = [];

  private currentProdLine: string;
  private surveyProdLines: string[] = [];

  private bsModalRef: BsModalRef;

  public availableFields: any[] = [];
  public selectedFields: any[] = [];
  public overwrittenFields: any[] = [];

  importItemFields: any[] = [];

  constructor(
    private alertService: AlertService,
    private customerService: CustomerService,
    private fileService: FileService,
    private modalService: ModalService,
    private store: Store,
    private utils: UtilService,
    public t: TranslateService
  ) {
    this.initImportItemFields();
  }

  async ngOnInit() {
    this.syntheticMaterials$ = this.store.select(
      BeltSelectors.selectSyntheticMaterials
    );
    this.modularMaterials$ = this.store.select(
      BeltSelectors.selectModularMaterials
    );
    this.modularTypes$ = this.store.select(BeltSelectors.selectModularTypes);
    this.modularColours$ = this.store.select(
      BeltSelectors.selectModularColours
    );
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.beltInput) {
      if (this.beltInput && !this.belt) {
        if (this.beltInput.beltAccess?.length) {
          this.beltAccess = this.beltInput.beltAccess.split(',');
        }

        this.belt = Object.assign({}, this.beltInput);
        this.setBeltProdLine(this.belt.productionLine);

        if (this.belt.image?.displayImage) {
          this.imagePreview = Image.fromUrl(
            (this.belt.image?.displayImage as any)
              .changingThisBreaksApplicationSecurity
          );
        } else {
          this.imagePreview = this.image =
            this.belt.image ?? Image.fromUrl(this.belt?.attachmentUrl);
        }
        this.existingImage = this.imagePreview;
        this.checkSynOrMod();
      }

      // TODO: update image display url with the attachement url if there is one, then dont change the attachment url on the belt
    }

    if (changes.survey) {
      if (this.survey) {
        const belts$: Observable<Belt[]> = this.store.select(
          BeltSelectors.selectSurveyBelts({ surveyId: this.survey.id })
        );
        belts$.subscribe((belts) => {
          let beltProdLines: any[] = belts.filter(
            (belt) => belt.productionLine && !belt.deleted
          );
          beltProdLines = beltProdLines.sort(
            (a, b) =>
              new Date(b.updated).getTime() - new Date(a.updated).getTime()
          );
          beltProdLines = beltProdLines.map((belt) => belt.productionLine);

          const prodLineNamesSet = new Set(beltProdLines);
          this.surveyProdLines = [...prodLineNamesSet].map(
            (prodLineName) => prodLineName
          );

          if (!this.belt?.productionLine && !this.belt?.id) {
            this.setBeltProdLine(this.surveyProdLines[0]);
          }
        });

        await this.fetchAmmcareItems();
      }
    }
  }

  private mapKeyValuesFromEnum(enumType): { key: string; value: string }[] {
    return Object.keys(enumType).map((type) => ({
      key: type,
      value: enumType[type],
    }));
  }

  private async fetchAmmcareItems() {
    try {
      this.ammcareItems = await this.customerService.fetchCustomerAmmcareBelts(
        this.survey.customerId
      );

      this.ammcareItems = this.ammcareItems.sort((a: any, b: any): any => {
        return this.utils.compare(
          a.machine_id?.toLowerCase(),
          b.machine_id?.toLowerCase()
        );
      });
    } catch (err) {
      console.log(err);
    }
  }

  updateBeltImage(event) {
    this.image = event;
    if (!event.file) {
      this.belt.attachmentUrl = null;
    }
  }

  async updateImage() {
    try {
      const isImageFileSet = !!this.image.file;
      const isImageDisplayImageSet = !!this.image.displayImage;
      const isImageSame =
        this.image.displayImage == this.existingImage.displayImage;
      const isFirstImageImport = isImageDisplayImageSet && !this.existingImage;
      const isOverwriteImport = isImageDisplayImageSet && !isImageSame;

      if (isImageFileSet || isFirstImageImport || isOverwriteImport) {
        if (this.image.file) {
          this.belt.image = await this.compressImageFile(this.image);
        } else if (
          this.image.displayImage &&
          typeof this.image.displayImage == 'string'
        ) {
          this.belt.attachmentUrl = this.image.displayImage;
        }
      } else if (isImageSame) {
        console.log('No image change');
      } else {
        console.log('Invalid image handling');
      }
    } catch (err) {
      this.alertService.handleHttpError(err);
    }
  }

  private async compressImageFile(image: Image): Promise<Image> {
    if (image && image.displayImage && typeof image.displayImage !== 'string') {
      if (image.file.size > 250000) {
        const compressedImageBlob: Blob = await this.fileService.compressImage(
          image.file
        );
        const compressedImageFile = new File(
          [compressedImageBlob],
          `${this.belt.id}-${new Date().getTime()}.jpg`
        );
        image.file = compressedImageFile;
        return image;
      } else {
        return Object.assign({}, image);
      }
    }
  }

  setBeltProdLine(productionLine: string) {
    this.belt = { ...this.belt, productionLine };
    this.currentProdLine = productionLine;
    this.beltForm.get('productionLine').setValue(productionLine);
  }

  getProdLineError(formControl: AbstractControl): string {
    const errorType = Object.keys(formControl.errors)[0];
    switch (errorType) {
      case 'required':
        return 'Required';
      case 'maxlength':
        return `Maximum length of ${formControl.errors.maxlength.requiredLength}`;
    }

    return errorType || '';
  }

  async onSave() {
    let didSetSyncOrMod =
      this.belt.synOrMod && this.belt.synOrMod !== '' ? true : false;
    if (this.belt.productReference === 'adminModular') {
      this.belt.wtl = this.belt.wtl ? this.belt.wtl : '1';
      this.belt.material = 'ok';
      this.belt.length = 9000;
      this.belt.width = 600;
      this.belt.quantity = 7;
      this.belt.synOrMod = 'modular';
      this.checkSynOrMod();
      this.belt.sprocketBore = '2';
      this.belt.sprocketPd = '3';
      this.belt.pinMaterial = 'coloured belt with dimensions:';
      this.belt.beltMaterial = 'a bright';
      this.belt.beltColour = 'Magenta ';
      this.belt.beltType = 'This is';
      this.belt.carriersBordoflex = 'Carrier';
      this.beltAccess = ['Hot environment'];
      this.belt.itemReference = 'REFa';
      this.belt.comments = '#test@';
      if (!didSetSyncOrMod) {
        setTimeout(() => this.onSave(), 250);
        return;
      }
    }

    if (this.belt.productReference === 'adminSynthetic') {
      this.belt.wtl = this.belt.wtl ? this.belt.wtl : '1';
      this.belt.material = 'SBRO572910';
      this.belt.length = 9000;
      this.belt.width = 600;
      this.belt.quantity = 9;
      this.belt.synOrMod = 'synthetic';
      this.checkSynOrMod();
      this.belt.joint = JointType.ENDLESS;
      this.belt.weldType = 'Business Splice';
      this.belt.weldDirection = 'Diagonal';
      this.belt.trackingGuide = 'Tracker';
      this.belt.comments = '#test@';
      this.belt.itemReference = 'REFa';
      this.belt.carriersBordoflex = 'Carriers';
      this.beltAccess = ['Cold environment'];
      if (!didSetSyncOrMod) {
        setTimeout(() => this.onSave(), 250);
        return;
      }
    }

    this.setBeltProdLine(this.currentProdLine);

    this.beltForm.markAsSubmitted();

    if (this.beltForm.submitted && !this.belt.synOrMod) {
      return;
    }

    const isSynWithoutJoint =
      this.belt.synOrMod === 'synthetic' && !this.belt.joint;

    if (this.belt.material && this.belt.synOrMod === 'synthetic') {
      if ((this.belt.material as any).label) {
        this.belt.material = (this.belt.material as any).label;
      }
    }

    this.belt.length = this.belt.length || 0;
    this.belt.width = this.belt.width || 0;
    this.belt.beltAccess = this.beltAccess.toString();

    if (this.belt.synOrMod === 'synthetic') {
      if (this.belt.joint['label']) {
        this.belt.joint = this.belt.joint['label'];
      }
    }

    if (this.beltForm.invalid || isSynWithoutJoint) {
      this.highlightErrors = true;
      this.alertService.alertWarning(
        this.t.translate('_BELT_INFORMATION_INCOMPLETE')
      );
      return;
    }

    await this.updateImage();
    this.beltChange.emit(this.belt);
  }

  public openImportDialog(dialog) {
    this.importFormSubmitted = false;
    this.ammcareItemOptionId = null;
    this.ammcareItem = null;
    this.resetImportFields();
    this.bsModalRef = this.modalService.show(dialog, { class: 'modal-md' });
  }

  closeImportDialog() {
    this.bsModalRef.hide();
  }

  toggleImport(item) {
    if (item.disabled) {
      return;
    }

    this.importFormSubmitted = false;
    item.selected = !item.selected;

    this.validateImportItem();
  }

  setAmmcareImportItem() {
    this.importFormSubmitted = false;
    this.ammcareItem = this.ammcareItems.find(
      (x) => this.ammcareItemOptionId == x.id
    );

    if (!this.ammcareItem) {
      this.importItemFields.forEach((field) => {
        field.disabled = true;
      });
    } else {
      this.importItemFields.forEach((field) => {
        field.selected = true;

        if (field.name == 'image') {
          field.disabled = !this.ammcareItem.images?.length;
        } else {
          field.disabled = !this.ammcareItem[field.name];
        }
      });

      this.validateImportItem();
    }
  }

  private checkAvailableFields() {
    this.availableFields = this.importItemFields.filter(
      (field) => !field.disabled
    );
  }

  private checkSelectedFields() {
    this.selectedFields = this.importItemFields.filter(
      (field) => field.selected && !field.disabled
    );
  }

  private checkForOverwrite() {
    this.overwrittenFields = [];
    this.selectedFields.forEach((field) => {
      if (field.name == 'image') {
        const hasImage =
          this.imagePreview.displayImage || this.imagePreview.file;
        if (hasImage) {
          this.overwrittenFields.push(field.translation);
        }
      } else {
        const value = this.beltForm.get(field.name).value;
        if (value) {
          this.overwrittenFields.push(field.translation);
        }
      }
    });
  }

  private checkSynOrMod() {
    if (this.belt.synOrMod === 'synthetic') {
      this.initSyntheticFields();
    } else if (this.belt.synOrMod === 'modular') {
      this.initModularFields();
    }
  }

  private initSyntheticFields() {
    // disable modular fields
    this.beltForm.get('sprocketBore').disable();
    this.beltForm.get('sprocketPd').disable();
    this.beltForm.get('pinMaterial').disable();
    this.beltForm.get('beltColour').disable();
    this.beltForm.get('beltMaterial').disable();
    this.beltForm.get('beltType').disable();

    // enable synthetic fields
    this.beltForm.get('material').enable();
  }

  private initModularFields() {
    // disable synthetic fields
    this.beltForm.get('material').disable();

    this.belt.joint = null;

    // enable modular fields
    this.beltForm.get('sprocketBore').enable();
    this.beltForm.get('sprocketPd').enable();
    this.beltForm.get('pinMaterial').enable();
    this.beltForm.get('beltColour').enable();
    this.beltForm.get('beltMaterial').enable();
    this.beltForm.get('beltType').enable();
  }

  private initImportItemFields() {
    this.importItemFields = [
      {
        name: 'image',
        logo: 'imagesmode',
        translation: `${this.t.translate('_IMAGE')}`,
        disabled: true,
        selected: true,
      },
      {
        name: 'productionLine',
        logo: 'conveyor_belt',
        translation: `${this.t.translate('_PRODUCTION_LINE')}`,
        disabled: true,
        selected: true,
      },
      {
        name: 'length',
        logo: 'height',
        translation: `${this.t.translate('_LENGTH')}`,
        disabled: true,
        selected: true,
      },
      {
        name: 'width',
        logo: 'width',
        translation: `${this.t.translate('_WIDTH')}`,
        disabled: true,
        selected: true,
      },
    ];
  }

  private resetImportFields() {
    this.selectedFields = [];
    this.availableFields = [];
    this.overwrittenFields = [];
    this.initImportItemFields();
  }

  private validateImportItem() {
    this.checkAvailableFields();
    this.checkSelectedFields();
    this.checkForOverwrite();

    if (!this.ammcareItemOptionId) {
      return;
    }

    if (!this.availableFields.length) {
      this.alertService.alertWarning('Item has no values to import.');
      return;
    }

    if (!this.selectedFields.length) {
      this.alertService.alertWarning('Please select at least one value.');
      return;
    }

    return true;
  }

  async importAmmcareItem() {
    this.importFormSubmitted = true;
    const isValid = this.validateImportItem();

    if (!isValid) {
      return;
    }

    try {
      const ammcareMachine = this.ammcareItems.find(
        (beltConfig) => this.ammcareItemOptionId == beltConfig.id
      );

      for (let field of this.importItemFields) {
        if (field.selected && !field.disabled) {
          switch (field.name) {
            case 'image':
              this.imagePreview = this.image = Image.fromUrl(
                ammcareMachine.images[0]?.imageUrl
              );
              break;
            case 'productionLine':
              this.currentProdLine = ammcareMachine.productionLine;
              this.setBeltProdLine(ammcareMachine.productionLine);
              break;
            case 'length':
              this.belt.length = ammcareMachine.length;
              break;
            case 'width':
              this.belt.width = ammcareMachine.width;
              break;
            default:
              break;
          }
        }
      }

      let importedProps = [];
      importedProps = this.importItemFields
        .filter((field) => field.selected && !field.disabled)
        .map((field) => field.translation);

      if (this.belt.image && !ammcareMachine.images[0]?.imageUrl) {
        this.alertService.alertWarning(
          `No image was found for this AMMcare item`
        );
      }
      this.alertService.alertSuccess(
        `${this.t.translate('_IMPORT_SUCCESSFUL_FOR')} ${importedProps.join(
          ', '
        )}`
      );

      this.closeImportDialog();
    } catch (err) {
      this.alertService.handleHttpError(err);
    }
  }
}
