export class ConveyorReader {
  id: string;

  machineName: string;
  machineNickName: string;
  conveyorName: string;
  description: string;
  diameter: Number;
  idleDrumDiameter: Number;
  customerProductReference: string;
  readerId: string;
}
