<div class="mobile mx-4">
  <div class="d-flex justify-content-between align-items-center overflow-hidden mt-3">
    <div *ngIf="addSpacer"></div>
    <h2 class="header text-center d-flex align-items-center">
      <i class="fa fa-angle-left me-4" [routerLink]="routerLinkUrl" *ngIf="routerLinkUrl"></i>
      <div class="flex-grow-1">{{ header }}</div>
    </h2>
    <ng-content select="[content]"></ng-content>
  </div>
</div>
