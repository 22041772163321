import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { CBelt } from 'src/app/models/cBelt.model';

import { AlertService } from 'src/app/shared/services/alert/alert.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-c-belt-configuration',
  templateUrl: './c-belt-configuration.component.html',
  styleUrls: ['./c-belt-configuration.component.scss'],
})
export class CBeltConfigurationComponent implements OnInit {
  belt: CBelt = new CBelt();
  isRndUser: boolean = false;
  current: ConfigurationState = null;

  constructor(
    private store: Store,
    private router: Router,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.subscribeToCurrentConfiguration();
  }

  subscribeToCurrentConfiguration() {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        if (this.current.belt && this.current.editing) {
          this.belt = cloneDeep(this.current.belt);
          this.belt.length = this.belt.length * 1000;
          this.belt.width = this.belt.width * 1000;
        }
      });
  }

  async openScanningPage() {
    if (!this.isValid()) {
      this.alertService.alertError('Please fill in fields to continue');
      return;
    }
    this.updateBeltState();
    this.router.navigate(['fitting', 'scanning', this.belt.orderNumber]);
  }

  async openManualEntryPage() {
    if (!this.isValid()) {
      this.alertService.alertError('Please fill in fields to continue');
      return;
    }
    this.updateBeltState();
    this.router.navigate([
      'fitting',
      'scanning',
      this.belt.orderNumber,
      'add-manually',
    ]);
  }

  navToFittingHome() {
    this.router.navigate(['c-belt']);
  }

  updateBeltState() {
    if (this.isRndUser || this.current.editing) {
      this.belt.width = this.belt.width / 1000;
      this.belt.length = this.belt.length / 1000;
    }
    this.store.dispatch(
      InstallationActions.setCurrentBelt({ belt: this.belt })
    );
    this.store.dispatch(InstallationActions.updateBelt({ belt: this.belt }));
  }

  isValid() {
    if (!this.isRndUser) {
      return this.belt.orderNumber;
    } else {
      return this.belt.orderNumber && this.belt.width && this.belt.length;
    }
  }

  toggleRndUser(event: any) {
    this.isRndUser = event.target.checked;
    this.store.dispatch(
      InstallationActions.updateIsRndUserStatus({ isRndUser: this.isRndUser })
    );
  }
}
