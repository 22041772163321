<div
  class="mobile"
  *ngIf="installation">
  <div class="px-2">
    <h2 class="text-center header mt-3">RFID Scanner</h2>
  </div>

  <div class="mx-3">
    <div
      class="bg-white card p-3 mt-4"
      *ngIf="!installation.rfidTagId">
      <div class="text-center">Connect the RFID reader to your phone and scan the belt chip</div>

      <div class="d-flex justify-content-center">
        <button
          *ngIf="!scanning"
          type="button"
          class="btn btn-primary mt-2"
          (click)="startScanning()">
          Start Scanning
        </button>
        <button
          *ngIf="scanning"
          type="button"
          class="btn btn-primary mt-2"
          (click)="stopScanning()">
          Stop Scanning
        </button>
      </div>

      <div class="small-grey text-center mt-2 px-5">
        Waiting for the reader to pick up the RFID signal
      </div>

      <div
        *ngIf="scanning"
        class="d-flex flex-column w-100 justify-content-center align-items-center mt-2">
        <div class="spinner-border text-primary"></div>
        <div>Scanning...</div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="installation.rfidTagId">
  <div class="mx-3">
    <div class="bg-white card p-3 mt-4">
      <div class="d-flex align-items-center justify-content-center mb-2">
        <div class="small wrap">
          RFID Tag ID:
          <span class="grey">
            {{ installation.rfidTagId }}
          </span>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-primary ms-2"
        (click)="resetInstall()">
        New Scan
      </button>
    </div>

    <div
      class="bg-white card p-3 mt-4"
      *ngIf="isValidInstall()">
      <div class="d-flex align-items-center mb-2">
        <div>Details:</div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Belt ID:
          <span class="grey">
            {{ installation.id ? installation.id : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Belt Item Reference:
          <span class="grey">
            {{ installation.orderNumber ? installation.orderNumber : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          RFID Tag ID:
          <span class="grey">
            {{ installation.rfidTagId ? installation.rfidTagId : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Conveyor ID:
          <span class="grey">
            {{ installation.conveyorId ? installation.conveyorId : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Organization ID:
          <span class="grey">
            {{ installation.organizationId ? installation.organizationId : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Installed UTC:
          <span class="grey">
            {{ installation.installedUtc ? formatDateTime(installation.installedUtc) : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          De-Installed UTC:
          <span class="grey">
            {{ installation.deinstalledUtc ? formatDateTime(installation.deinstalledUtc) : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Width (m):
          <span class="grey">
            {{ installation.width ? installation.width : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Length (m):
          <span class="grey">
            {{ installation.length ? installation.length : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Conveyor Name:
          <span class="grey">
            {{ installation.conveyorName ? installation.conveyorName : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Machine ID:
          <span class="grey">
            {{ installation.machineId ? installation.machineId : "N/A" }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="small wrap">
          Machine Name:
          <span class="grey">
            {{ installation.machineName ? installation.machineName : "N/A" }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
