import { Injectable, NgZone } from '@angular/core';

import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';

export enum MsalScope {
  cBelt = 'cBelt',
  surveys = 'surveys',
}

@Injectable({ providedIn: 'root' })
export class MsalCBeltService {
  private cBeltClientId = environment.cBeltClientId;
  private ammscanApiScope = environment.ammscanApiScope;

  constructor(private ngZone: NgZone, private authService: AuthService) {}

  msalInit(scope: MsalScope): Promise<void> {
    return new Promise((resolve, reject) => {
      window['cordova'].plugins.msalPlugin.msalInit(
        () => resolve(),
        () => reject(),
        {
          authorities: [
            {
              type: 'B2C',
              authorityUrl:
                'https://connectedbelt.b2clogin.com/tfp/connectedbelt.onmicrosoft.com/B2C_1_Connected_Belt/',
              cloudInstance: 'MSALAzurePublicCloudInstance',
              default: true,
            },
          ],
          authorizationUserAgent: 'WEBVIEW',
          multipleCloudsSupported: false,
          brokerRedirectUri: false,
          accountMode: 'SINGLE',
          scopes: scope == MsalScope.surveys ? [this.ammscanApiScope] : [this.cBeltClientId],
          webViewZoomControlsEnabled: false,
          webViewZoomEnabled: false,
          powerOptCheckForNetworkReqEnabled: true,
          clientId: this.cBeltClientId,
          tenantId: 'common',
        }
      );
    });
  }

  cBeltLogin(): Promise<void> {
    return new Promise((resolve, reject) => {
      window['cordova'].plugins.msalPlugin.signInSilent(
        (resp) =>
          this.ngZone.run(async () => {
            await this.onSignInSuccess(resp);
            resolve();
          }),
        () =>
          this.ngZone.run(async () => {
            await this.interactiveSignIn();
            resolve();
          })
      );
    });
  }

  async onSignInSuccess(resp) {
    const userData = this.getUserDetails(resp);
    await this.authService.onMsalAuthChange(userData, {
      fetchOrgPermissions: true,
    });
  }

  interactiveSignIn(): Promise<void> {
    return new Promise((resolve, reject) => {
      window['cordova'].plugins.msalPlugin.signInInteractive(
        (resp) =>
          this.ngZone.run(async () => {
            await this.onSignInSuccess(resp);
            resolve();
          }),
        (err) =>
          this.ngZone.run(async () => {
            await this.interactiveSignIn();
            resolve();
          }),
        { prompt: 'LOGIN' }
      );
    });
  }

  signOut(): Promise<void> {
    return new Promise((resolve, reject) => {
      window['cordova'].plugins.msalPlugin.signOut(
        (resp) =>
          this.ngZone.run(async () => {
            await this.onSignOutSuccess();
            resolve();
          }),
        () =>
          this.ngZone.run(() => {
            this.interactiveSignIn();
            resolve();
          })
      );
    });
  }

  async onSignOutSuccess() {
    await this.authService.onMsalAuthChange(null);
  }

  private getUserDetails(response) {
    let id: string;
    let email: string;
    const claims = response['account']['claims'];
    for (const claim of claims) {
      if (claim.key == 'sub') {
        id = claim.value;
      }
      if (claim.key == 'emails') {
        email = claim.value[0];
      }
    }
    return {
      id: id,
      email: email,
      token: response.token,
    };
  }
}
