import { Component } from '@angular/core';

import { AlertService } from 'src/app/shared/services/alert/alert.service';

@Component({
  selector: 'app-inspection-list',
  templateUrl: './inspection-list.component.html',
  styleUrls: ['./inspection-list.component.scss'],
})
export class InspectionListComponent {
  inspections = [];

  constructor(private alertService: AlertService) {}

  alertComingSoon() {
    this.alertService.alertInfo('Coming Soon!');
  }
}
