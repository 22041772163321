import { Injectable } from "@angular/core";
import { Papa } from 'ngx-papaparse';

import { IFile, appstraxStorage } from '@appstrax/services/storage';
import { compressAccurately, EImageType } from 'image-conversion';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private papaParse: Papa,
  ) { }

  public async uploadImage(file): Promise<{ downloadUrl: string; file: IFile; }> {
    return await appstraxStorage.uploadFile(file, 'images');
  }

  public async compressImage(file: File): Promise<Blob> {
    return compressAccurately(file, {
      type: EImageType.JPEG,
      accuracy: 0.9,
      size: 200,
      width: 800
    });
  }

  public readCsvFile(file: File): Promise<any[]> {
    const result = new Promise<any>((resolve, reject) => {
      this.papaParse.parse(file, {
        complete: (result) => {
          const objectArr = this.preprocessCsvData(result.data);
          resolve(objectArr);
        },
        error: (error, file) => {
          reject(error);
        }
      });
    });

    return result;
  }

  private preprocessCsvData(csvRows): any[] {
    const headers = csvRows[0].toString().toLowerCase().replace(/ /g, '_').split(',');
    const rows = csvRows.slice(2);
    const array = rows.map((row) => {
      let obj = {};
      let index = 0;
      row.forEach((column) => {
        if (index >= 0) {
          const header = headers[index];
          obj[header] = column.replace(/['"\r]+/g, '').trim();
        }
        index++;
      });
      return obj;
    });
    return array;
  }
}
