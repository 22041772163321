import { cloneDeep } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Gateway } from 'src/app/models/gateway.model';

import { AlertService } from 'src/app/shared/services/alert/alert.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-configure-wifi',
  templateUrl: './configure-wifi.component.html',
  styleUrls: ['./configure-wifi.component.scss'],
})
export class ConfigureWifiComponent implements OnInit {
  editing: boolean;
  companyId: string;
  gatewayDevice: Gateway = new Gateway();
  current: ConfigurationState = null;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToCurrentConfiguration();
    });
  }

  subscribeToCurrentConfiguration() {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.editing = this.current.editing;
        this.gatewayDevice = cloneDeep(this.current.gateway);
      });
  }

  isValid() {
    if (this.gatewayDevice.wifiType == 'Static') {
      return (
        this.gatewayDevice.networkName &&
        this.gatewayDevice.devicePassword &&
        this.gatewayDevice.ipAddress &&
        this.gatewayDevice.networkMask &&
        this.gatewayDevice.gatewayAddress &&
        this.gatewayDevice.dns &&
        this.gatewayDevice.wifiType
      );
    } else {
      return this.gatewayDevice.networkName && this.gatewayDevice.devicePassword;
    }
  }

  returnTitle() {
    return `Configure Wifi ${this.gatewayDevice.wifiType ? this.gatewayDevice.wifiType : 'Static'}`;
  }

  navToConfiguring() {
    if (!this.isValid()) {
      this.alertService.alertError('Please fill in all fields');
      return;
    }
    this.store.dispatch(InstallationActions.updateTempEditingStatus({ tempEdit: false }));
    this.store.dispatch(
      InstallationActions.setCurrentGatewayDevice({ gatewayDevice: this.gatewayDevice })
    );
    this.router.navigate([
      '/installing',
      this.companyId,
      'gateway-configuration',
      'new-gateway-device',
      'configure-wifi',
      'configuring-device',
    ]);
  }
}
