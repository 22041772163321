import { cloneDeep } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Sensor } from '../../../../models/sensor.model';
import { Conveyor } from '../../../../models/conveyor.model';

import { SensorsService } from '../../../../services/c-belt/sensors.service';
import { AlertService } from '../../../../shared/services/alert/alert.service';
import { LoadingService } from '../../../../shared/services/loading/loading.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from '../../../../state/installation/installation.state';
import * as InstallationActions from '../../../../state/installation/installation.actions';
import * as InstallationSelectors from '../../../../state/installation/installation.selector';

@Component({
  selector: 'app-sensor-configuration',
  templateUrl: './sensor-configuration.component.html',
  styleUrls: ['./sensor-configuration.component.scss'],
})
export class SensorConfigurationComponent implements OnInit {
  baseRoute: string;
  companyId: string;
  sensors: Sensor[] = [];
  conveyor: Conveyor = null;
  current: ConfigurationState = null;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private sensorService: SensorsService,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.route.url.subscribe((route) => {
      this.baseRoute = route[0].path;
    });
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
    });
    this.subscribeToCurrentConveyor();
    this.subscribeToCurrentConfiguration();
  }

  subscribeToCurrentConfiguration() {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
      });
  }

  subscribeToCurrentConveyor() {
    const select = InstallationSelectors.selectCurrentConveyor();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((conveyor) => {
        this.conveyor = cloneDeep(conveyor);
        this.fetchSensors();
      });
  }

  async fetchSensors() {
    this.loadingService.show();
    try {
      this.sensors = await this.sensorService.getSensors(this.conveyor.id);
      this.store.dispatch(InstallationActions.addExistingSensors({ sensors: this.sensors }));
    } catch (err) {
      this.alertService.handleCBeltError(err);
    }
    this.loadingService.hide();
  }

  returnSensorType(id: Number) {
    switch (id) {
      case 1:
        return 'Belt Speed/RFID Reader';
      case 2:
        return 'Humidity';
      case 3:
        return 'Acceleration';
      case 4:
        return 'Temperature';
      case 5:
        return 'Drum Speed';
      default:
        return 'Unknown Sensor Type';
    }
  }

  navToAddSensor() {
    this.router.navigate([
      this.baseRoute,
      this.companyId,
      'sensor-configuration',
      'add-edit-sensors',
    ]);
  }

  navToEditSensor(sensor: Sensor) {
    this.store.dispatch(InstallationActions.addSensorToEdit({ sensor: sensor }));
    this.router.navigate([this.baseRoute, this.companyId, 'sensor-configuration', 'edit-sensor']);
  }

  returnTitle() {
    return `Sensors for ${this.conveyor.aliasName}`;
  }
}
