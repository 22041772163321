import { TranslateService } from './../../../services/translate.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Image } from '../../../../app/shared/components/image-input/models/image';
import { AlertService } from './../../services/alert/alert.service';

@Component({
  selector: 'image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss']
})
export class ImageInputComponent {

  @Input() image: Image;
  @Output() imageChange: EventEmitter<Image> = new EventEmitter();
  @Output() change: EventEmitter<Image> = new EventEmitter();

  @Input() size: number = 325;

  @Input() error = '';
  @Input() maxKb: number;
  @Input() label: string;

  constructor(
    private sanitizer: DomSanitizer,
    private alertService: AlertService,
    public t: TranslateService
  ) { }

  onFileSelected(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];

      if (this.maxKb && (file.size / 1000) > this.maxKb) {
        this.alertService.alertError('This image is too large, Max Size is ' + this.maxKb + 'Kb');
        event.target.value = '';
        return;
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const display = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result);

        this.image = {
          file: file,
          displayImage: display,
          isNew: true
        }
        this.imageChange.emit(this.image);
        this.change.emit(this.image);
      };
      reader.readAsDataURL(file);
      event.target.value = '';
    }
    event.stopPropagation();
  }

  public getIconSize() {
    let size;
    if (this.size < 30) {
      size = 30;
    } else if (this.size > 72) {
      size = 72;
    } else {
      size = this.size;
    }
    return size - 10;
  }

  clear() {
    this.image = new Image();
    this.imageChange.emit(this.image);
    this.change.emit(this.image);
  }
  
  async download() {
    const downloadLink = document.createElement('a');
    
    if (!this.image.file) {
      const response = await fetch(this.image.displayImage.toString(), {
        headers: new Headers({
          'Origin': location.origin
        }),
        mode: 'cors'
      })
      const imageBlob = await response.blob();
      downloadLink.href = window.URL.createObjectURL(imageBlob);
    } else {
      downloadLink.href = window.URL.createObjectURL(this.image.file);
    }
    
    downloadLink.target = '_blank';
    downloadLink.setAttribute('download', 'Image');

    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}
