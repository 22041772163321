import { NFC } from '@awesome-cordova-plugins/nfc/ngx';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import * as InstallationActions from 'src/app/state/installation/installation.actions';

@Component({
  selector: 'app-add-sensors',
  templateUrl: './add-sensors.component.html',
  styleUrls: ['./add-sensors.component.scss'],
})
export class AddSensorsComponent implements OnInit, OnDestroy {
  tagId: any;
  enabled: boolean;
  baseRoute: string;
  companyId: string;

  private scannerSub: Subscription;

  qrConfig = {
    preferFrontCamera: false,
    showFlipCameraButton: true,
    showTorchButton: true,
    torchOn: false,
    saveHistory: true,
    prompt: 'Place a barcode inside the scan area',
    resultDisplayDuration: 500,
    formats: 'QR_CODE,PDF_417',
    orientation: 'portrait',
    disableAnimations: true,
    disableSuccessBeep: false,
  };

  constructor(
    private nfc: NFC,
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.route.url.subscribe((route) => {
      this.baseRoute = route[0].path;
    });
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
    });
    this.addListener();
  }

  async ngOnDestroy(): Promise<void> {
    if (this.scannerSub) {
      this.scannerSub.unsubscribe();
    }
  }

  async addListener() {
    try {
      this.alertService.alertInfo('Attempting to Scan');
      this.scannerSub = this.nfc.addNdefListener().subscribe({
        next: (tag) => {
          let payload = tag['tag']['ndefMessage'][0]['payload'];
          let stringValue = this.nfc.bytesToString(payload);
          let hexValue = stringValue.slice(3, 20);
          this.tagId = this.macAddressToDecimal(hexValue);
        },
        error: (err) => {
          if (err == 'NFC_DISABLED') {
            this.enabled = false;
            this.alertService.alertError('Please Enable NFC');
          }
        },
        complete: () => {
          console.log('Cameron: Complete');
        },
      });
    } catch (err) {
      this.alertService.alertError(JSON.stringify(err));
    }
  }

  openAndScanQR() {
    window['cordova'].plugins['barcodeScanner'].scan(
      (result) => this.ngZone.run(() => (this.tagId = result.text)),
      (err) => this.ngZone.run(() => this.onQRScanError(err)),
      this.qrConfig
    );
  }

  onQRScanError(err) {
    this.alertService.alertError('QR Scan Failed: ' + JSON.stringify(err));
  }

  navToAddSensorManually() {
    if (this.tagId) {
      this.updateSensorId();
    }
    this.router.navigate([
      this.baseRoute,
      this.companyId,
      'sensor-configuration',
      'add-edit-sensors',
      'add-sensor-manually',
    ]);
  }

  updateSensorId() {
    this.store.dispatch(
      InstallationActions.updateNewSensorId({ sensorId: this.tagId })
    );
  }

  macAddressToDecimal(macAddress: string): number {
    const macParts = macAddress.split(':');
    let decimalNumber = 0;

    for (let i = 0; i < macParts.length; i++) {
      const hexPart = parseInt(macParts[i], 16);
      decimalNumber = decimalNumber * 256 + hexPart;
    }

    return decimalNumber;
  }
}
