<div class="mobile" *ngIf="current">
  <app-mobile-header
    [header]="'Configuration Overview'"
    [routerLinkUrl]="['/installing', companyId]"
  >
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <div class="accordion" id="accordionExample">
      <div class="accordion-item" *ngFor="let machine of current.machines; let i = index">
        <h2 class="accordion-header">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#collapse' + i"
            aria-expanded="false"
            [attr.aria-controls]="'collapse' + i"
            (click)="selectMachine(machine)"
          >
            {{ "Machine: " + machine.machineName }}
          </button>
        </h2>
        <div
          [id]="'collapse' + i"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <app-company-validation [customer]="current.company"></app-company-validation>

            <div *ngFor="let gateway of current.gateways">
              <app-gateway-validation [gateway]="gateway"></app-gateway-validation>
            </div>

            <div *ngFor="let conveyor of conveyors">
              <app-conveyor-validation
                [conveyor]="conveyor"
                [containsRfidReader]="containsRfidReader"
                [sensors]="getSensorsForConveyor(conveyor.id)"
                [conveyorReader]="
                  getConveyorReadersForMachineAndConveyor(machine.id, conveyor.aliasName)
                "
              ></app-conveyor-validation>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white card mt-3 p-3" *ngIf="!current.machines.length">Nothing to validate</div>
    </div>

    <div class="d-flex mx-3 mb-5">
      <button type="button" (click)="addConfigToHistory()" class="btn btn-primary mt-4 w-100">
        {{ editing ? "Update Installation" : "Finish Installation" }}
      </button>
    </div>
  </div>
</div>
